import React from "react";
import Button from "@material-ui/core/Button";
// styles
import { FormButtonsStyle } from "./FormButtons.styles";
// recoil
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { formIsOpen, formData, snackbarData } from '../../../recoilState/GlobalState';

export default function FormButton(props) {
    const { handleAction, setSecondaryOpen, inRequest, newFormData } = props;
    
    const data = useRecoilValue(formData);
    const { open } = useRecoilValue(snackbarData);
    const setIsOpen = useSetRecoilState(formIsOpen);
    
    const classes = FormButtonsStyle();
    const className = data.title.includes("Eliminar") || data.title.includes("Desvincular") ? classes.redBtn : classes.defaultBtn;

    return (
        <div className={classes.container}>
            <Button
                type="submit"
                disabled={inRequest || open}
                onClick={handleAction}
                className={className}
            >
                <span>{newFormData ? newFormData.button : data.button}</span>
            </Button>
            <Button
                disabled={inRequest || open}
                className={classes.whiteBtn}
                onClick={setSecondaryOpen ? () => setSecondaryOpen(false) : () => setIsOpen(false)}
            >
                <span>Cancelar</span>
            </Button>
        </div>
    );
}
