import React, { useState, useEffect } from "react";
// utils
import FormButton from "../../button/FormButton";
import CommonForm from "../CommonForm";
import TextField from "@material-ui/core/TextField"
// functions
import { postCoeficiente } from './CoeficientesFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './CoeficientesFormValidaciones';
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState';
import { FormikDateInput, FormikTextInput } from "../../inputs/FormikInputs";

export default function CoeficientesForm(props) {
    const [newCoeficiente, setNewCoeficiente] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData)
    const setSelected = useSetRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { id, updateList, procede } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Addone') {
            const response = await postCoeficiente(credentials, newCoeficiente, id, setInRequest)
            return handleResponse(response, 'Coeficiente añadido exitosamente', functions)
        }
    };

    useEffect(() => handleAction(), [newCoeficiente]);

    const formik = formikValidation(setNewCoeficiente);

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikTextInput
                data={data}
                name='cuotas'
                formik={formik}
                disabled={inRequest}
            />
            <TextField
                label={data.tipo.label}
                name='tipo'
                placeholder={data.tipo.placeholder}
                margin="normal"
                select
                disabled={inRequest}
                SelectProps={{ native: true }}
                value={formik.values.tipo}
                onChange={formik.handleChange}
                error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                helperText={formik.touched.tipo && formik.errors.tipo}
                fullWidth
            >
                <option value="C">Con interes</option>
                <option value="S">Sin interes</option>
                <option value="H">Hooli</option>
            </TextField>
            <FormikDateInput
                data={data}
                name='vigencia'
                formik={formik}
                disabled={inRequest}
            />
            <FormikTextInput
                data={data}
                name='valor'
                formik={formik}
                disabled={inRequest}
            />
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};