// services
import { tarjetasCoeficientesIns } from '../../../../services/hooli-services/TarjetasCoeficientes/TarjetasCoeficientesIns';

export async function postCoeficiente(credentials, newCoeficiente, id, setInRequest) {
    if (Object.keys(newCoeficiente).length > 0) {
        const payload = {
            id,
            ...newCoeficiente,
            valor: newCoeficiente.valor.replace(/\,/g, '.'),
            ...credentials
        };

        setInRequest(true);
        const response = await tarjetasCoeficientesIns(payload);
        return response
    }
};