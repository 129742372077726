import React from 'react';
import HeaderButton from "../../components/utils/button/HeaderButton";
import SubmitButton from '../../components/utils/button/SubmitButton';
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import { FormikDateInput } from '../../components/utils/inputs/FormikInputs';
// styles
import { FiltrosStyles } from '../Filtros.styles';
// icons
import reload from "../../assets/icons/actualizar.svg";
import conciliar from "../../assets/icons/conciliar.png";
import revisar from "../../assets/icons/revisar.svg";
// form
import * as yup from "yup";
import { useFormik } from "formik";

export default function Filtros(props) {
    const { filtros, setFiltros, selected, handleClick, updateList, cargando } = props;

    const validationSchema = yup.object({
        desde: yup
            .date()
            .max(yup.ref('hasta'), "No puede ser posterior a la segunda fecha")
            .required("Por favor seleccione una fecha"),
        hasta: yup
            .date()
            .max(new Date().toISOString().substring(0, 10), "Fecha maxima hoy")
            .required("Por favor seleccione una fecha")
    });

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const classes = FiltrosStyles();
    const data = { desde: { label: 'Desde' }, hasta: { label: 'Hasta' } };

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <FormikDateInput
                    data={data}
                    name="desde"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="hasta"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Filtrar" />
                </div>
            </div>
            <Buttons>
                {selected.length > 0 ?
                    <>
                        <HeaderButton
                            text={'Revisar'}
                            icon={revisar}
                            handleClick={handleClick}
                            data={{ action: 'noOpen', procede: 'revisar' }}
                        />
                        <HeaderButton
                            text={'Conciliar'}
                            icon={conciliar}
                            handleClick={handleClick}
                            data={{ action: 'noOpen', procede: 'conciliar' }}
                        />
                    </>
                    :
                    <HeaderButton
                        text={'Actualizar pagina'}
                        icon={reload}
                        updateList={updateList}
                    />
                }
            </Buttons>
        </form>
    )
};