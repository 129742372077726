// services
import { conciliacionesConciliar } from "../../services/hooli-services/Conciliaciones/Conciliar";
import { conciliacionesRevisar } from "../../services/hooli-services/Conciliaciones/Revisar";
import { conciliacionesOcultar } from "../../services/hooli-services/Conciliaciones/Ocultar";
import { conciliacionesRevertir } from "../../services/hooli-services/Conciliaciones/Revertir";

import { serviceURL } from "../../consts/ServiceURL";
import { conciliacionesUpload } from "../../services/hooli-services/Conciliaciones/Upload";
import { conciliacionesCompletar } from "../../services/hooli-services/Conciliaciones/Completar";

export async function update(credentials, selected, procede, archivo, comentario, tipoDeArchivo) {
    const { hooliToken, sessionId } = credentials;

    const operaciones = selected ? selected.map(item => {
        return {
            origin: item.hooliId ? "hooli" : "bind",
            id: item.hooliId ? item.hooliId : item.bindId
        }
    }) : null;

    const payload = {
        comentario,
        archivo,
        tipoDeArchivo,
        operaciones,
        hooliToken,
        sessionId,
        serviceURL
    };

    switch (procede) {
        case "conciliar": return await conciliacionesConciliar(payload);
        case "revisar": return await conciliacionesRevisar(payload);
        case "ocultar": return await conciliacionesOcultar(payload);
        case "revertir": return await conciliacionesRevertir(payload);
        case "desconciliar": return await conciliacionesRevertir(payload);
        case "archivo": return await conciliacionesUpload(payload);
        case "completar": return await conciliacionesCompletar(payload);
        default: return
    }
};