import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup.string().required("Ingrese un nombre"),
    tipo: yup.string().required("Seleccione una opción"),
    codigo: yup.string().required("Ingrese un codigo"),
    marcaCod: yup.string().max(4, "Debe tener como maximo 4 caracteres").required("Ingrese un codigo"),
});

export const formikValidation = (setNewMarca, row, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : '',
        tipo: row && row.tipo ? row.tipo : 'C',
        codigo: row && row[titles[2]] ? row[titles[2]] : '',
        marcaCod: row && row[titles[3]] ? row[titles[3]] : '',
    },
    validationSchema,
    onSubmit: (values) => setNewMarca(values)
});