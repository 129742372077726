import React, { useState } from "react";
// utils
import FormButton from "../../button/FormButton";
import CommonForm from "../CommonForm";
import { FormikSelectInput, FormikTextInput } from "../../inputs/FormikInputs";
// functions
import { postPlastico, updatePlastico } from './PlasticosFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './PlasticosFormValidaciones';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState';

export default function PlasticosForm(props) {
    const [newPlastico, setNewPlastico] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData)
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede, marcas, emisores } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Addone') {
            const response = await postPlastico(credentials, newPlastico, marcas, emisores, setInRequest)
            return handleResponse(response, 'Plastico añadido exitosamente', functions)
        }
        if (procede === 'Editone') {
            const response = await updatePlastico(credentials, newPlastico, selected.row, emisores, setInRequest)
            return handleResponse(response, 'Plastico modificado exitosamente', functions)
        }
    };

    const formik = formikValidation(setNewPlastico, selected.row, titles, marcas, emisores);

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikTextInput
                data={data}
                name='numero'
                formik={formik}
                disabled={procede === 'Editone' ? true : inRequest}
            />
            <FormikSelectInput
                data={data}
                name='marca'
                formik={formik}
                disabled={procede === 'Editone' ? true : inRequest}
                options={marcas}
                optionId='gwcardMarcaId'
                optionValue='gwcardMarcaId'
                optionName='gwcardMarcaNombre'
            />
            <FormikSelectInput
                data={data}
                name='emisor'
                formik={formik}
                disabled={inRequest}
                options={emisores}
                optionId='gwcardEmisorId'
                optionValue='gwcardEmisorId'
                optionName='gwcardEmisorNombre'
            />
            <FormikTextInput
                data={data}
                name='codigo'
                formik={formik}
                disabled={inRequest}
            />
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};