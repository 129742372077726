import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup.string().required("Ingrese un nombre"),
    codigo: yup.string().max(4, 'Maximo 4 caracteres').required("Ingrese un codigo"),
    bancario: yup.string().required("Seleccione una opción"),
});

export const formikValidation = (setNewEmisor, row, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : '',
        codigo: row && row[titles[1]] ? row[titles[1]] : '',
        bancario: row && row.bancario ? row.bancario === 'B' ? 'Y' : 'N' : 'Y',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => setNewEmisor(values)
});