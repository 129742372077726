import React, { useState } from "react";
// Utils
import FormButton from "../../components/utils/button/FormButton";
import CommonForm from "../../components/utils/form/CommonForm";
// Services
import { formatNumber, handleResponse } from "../../services/Local-services";
import { conciliacionesReverso } from "../../services/hooli-services/Conciliaciones/Reverso";
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, snackbarData, formData } from '../../recoilState/GlobalState'

export default function ConfirmarReversoModal(props) {
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { selected, setSelected, updateList } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };

        let ids;
        let tipo;
        if (selected.every(item => item.origen)) {
            ids = selected.map(item => item.origen)
            tipo = 'H';
        }
        else {
            ids = selected.map(item => item.bindId)
            tipo = 'B';
        };
        const payload = { ...credentials, ids, tipo };

        setInRequest(true);
        const response = await conciliacionesReverso(payload);

        return handleResponse(response, 'Operacion exitosa', functions, true)
    };
    return (
        <CommonForm
            onSubmit={() => { }}
            title={data.title}
        >
            <p>Esta seguro que desea reversar {selected.length > 1 ? 'las operaciones' : 'la operación'} con ID:</p>
            {selected.map(item => <p key={item.bindId || item.origen} style={{ margin: '0.5em 0' }}>{item.bindId || item.origen}</p>)}
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};