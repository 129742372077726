import React, { useState } from "react";
import { Divider } from "@material-ui/core";
import classes from './ConfirmarAcreditacionModal.module.css';
// functions
import { tarjetasAcreditacionesAcreditar } from "../../services/hooli-services/TarjetasAcreditaciones/TarjetasAcreditacionesAcreditar";
import { formatNumber, handleResponse } from "../../services/Local-services";
// utils
import FormButton from "../../components/utils/button/FormButton";
import CommonForm from "../../components/utils/form/CommonForm";
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, snackbarData, formData } from '../../recoilState/GlobalState'

export default function ConfirmarAcreditacionModal(props) {
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { updateList, filtros, selected, setSelected } = props.props;

    async function handleAction(e) {
        e.preventDefault();
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        const ids = selected.map(item => item.auto === 'AA' ? null : item.auto);
        const autom = ids.some(id => id === null);

        const params = {
            "gwcardFechaAcreditacion": filtros.fecha,
            "gwcardAcreditarAutomaticos": autom ? 'S' : 'N',
            "gwcardAcreditacionMerchantIds": ids.filter(id => id !== null)
        };

        const payload = { ...credentials, params };

        setInRequest(true);
        const response = await tarjetasAcreditacionesAcreditar(payload);

        return handleResponse(response, 'Acreditación completa', functions);
    };

    const infoInicial = { monto: 0, operaciones: 0, merchants: 0 };
    const info = Array.isArray(selected) ? selected.reduce((acc, item) => {
        return {
            monto: acc.monto + item["Monto"],
            operaciones: acc.operaciones + item["Total operaciones"],
            merchants: acc.merchants + parseInt(item["Nombre Merchant"].split(" ")[0])
        }
    }, infoInicial) : infoInicial;

    return (
        <CommonForm
            title={data.title}
            onSubmit={(e) => handleAction(e)}
        >
            <div className={classes.detalle}>
                <div className={classes.nombre}>Numero de merchants</div>
                <div className={classes.info}>{info.merchants}</div>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.detalle}>
                <div className={classes.nombre}>Total operaciones</div>
                <div className={classes.info}>{info.operaciones}</div>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.detalle}>
                <div className={classes.nombre}>Monto</div>
                <div className={classes.info}>{formatNumber(info.monto)}</div>
            </div>
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm >
    );
};