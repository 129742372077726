import axiosPost from '../axiosPost';

export const tarjetasMarcasIns = async (arg) => {
    const { nombre, tipo, codigo, marcaCod } = arg
    
    const body = {
        "service": "GWCardMarcaIns",
        "params": {
            "gwcardMarcaNombre": nombre,
            "gwcardMarcaTipo": tipo,
            "gwcardMarcaCodigoLine": codigo,
            "gwcardMarcaCod": marcaCod,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
}