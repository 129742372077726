import axiosPost from '../axiosPost';

export const conciliacionesGireConciliar = async (arg) => {
    const { operaciones } = arg

    const body = {
        "service": "GireConciliacionConciliar",
        "params": {
            "gireConciliacionOperaciones": operaciones
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
};