import React, { useState, useEffect } from "react";
// utils
import FormButton from "../../button/FormButton";
import CommonForm from "../CommonForm";
import { FormikTextInput, FormikYesOrNoInput } from "../../inputs/FormikInputs";
// functions
import { postEmisor, deleteEmisor, updateEmisor } from './EmisoresFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './EmisoresFormValidaciones';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState';

export default function EmisoresForm(props) {
    const [newEmisor, setNewEmisor] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData)
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Deleteone') {
            const response = await deleteEmisor(credentials, selected.row, setInRequest);
            return handleResponse(response, 'Emisor eliminado exitosamente', functions);
        }
        if (procede === 'Addone') {
            const response = await postEmisor(credentials, newEmisor, setInRequest)
            return handleResponse(response, 'Emisor añadido exitosamente', functions);
        }
        if (procede === 'Editone') {
            const response = await updateEmisor(credentials, newEmisor, selected.row, setInRequest)
            return handleResponse(response, 'Emisor modificado exitosamente', functions);
        }
    };

    useEffect(() => {
        if (procede !== 'Deleteone') handleAction();
    }, [newEmisor])

    const formik = formikValidation(setNewEmisor, selected.row, titles);
    const disabled = procede === 'Deleteone' || inRequest;

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikTextInput
                data={data}
                name='nombre'
                formik={formik}
                disabled={disabled}
            />
            <FormikTextInput
                data={data}
                name='codigo'
                formik={formik}
                disabled={disabled}
            />
            <FormikYesOrNoInput
                data={data}
                name='bancario'
                formik={formik}
                disabled={disabled}
            />
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};