import React, { useState } from "react";
import { Divider } from "@material-ui/core";
// styles
import "../../components/utils/form/form.css";
// utils
import FormButton from "../../components/utils/button/FormButton";
import { serviceURL } from "../../consts/ServiceURL";
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, snackbarData } from '../../recoilState/GlobalState'
import { tarjetasAcreditacionesAcreditar } from "../../services/hooli-services/TarjetasAcreditaciones/TarjetasAcreditacionesAcreditar";
import { transformDate } from "../../services/Local-services";

export default function ConfirmarTransferenciaModal(props) {
    const [openAlert, setOpenAlert] = useState(false);
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const { data, formValues, usuario, categoria } = props.props;

    async function handleAction() {
        const { sessionId, hooliToken } = credentials;

        const params = {}
        const payload = { sessionId, hooliToken, serviceURL, params };

        setInRequest(true);
        const response = await tarjetasAcreditacionesAcreditar(payload);

        return handleResponse(response, 'Operacion exitosa')
    };

    function handleResponse(response, message) {
        if (response) {
            if (response.status.code === 1) {
                setSnackbarInfo({
                    message,
                    severity: 'success'
                })
                setInRequest(false)
                setOpenAlert(true)
                setTimeout(() => {
                    setOpenAlert(false)
                    setIsOpen(false)
                }, 3000);
            }
            else {
                setSnackbarInfo({
                    message: response.status.errmsg,
                    severity: response.status.action === 'W' ? 'warning' : 'error'
                })
                setInRequest(false)
                setOpenAlert(true)
                setTimeout(() => setOpenAlert(false), 3000);
            }
        }
    };

    return (
        <div className="form">
            <form
                style={{ width: "100%", height: "100%" }}
                autoComplete="off"
            >
                <div className="title">
                    <h2>{data.title}</h2>
                </div>
                <div className="divider">
                    <Divider />
                </div>
                <div className="inputs">
                    <div className="form-container">
                        <div className="input-container subir-archivo">
                            <div className="detalle-movimiento">
                                <div className="nombre-movimiento">{categoria === 'M' ? 'Merchant' : 'Usuario'}</div>
                                <div className="info-movimiento acreditar">
                                    {usuario ? categoria === 'M' ? usuario.merchantNombre : `${usuario.usuarioApellidos} ${usuario.usuarioNombres}` : ''}
                                </div>
                            </div>
                            <div className="divider-movimiento">
                                <Divider />
                            </div>
                            <div className="detalle-movimiento">
                                <div className="nombre-movimiento">Hooli coins</div>
                                <div className="info-movimiento acreditar">{formValues ? formValues.hooliCoins : ''}</div>
                            </div>
                            <div className="divider-movimiento">
                                <Divider />
                            </div>
                            <div className="detalle-movimiento">
                                <div className="nombre-movimiento">Fecha</div>
                                <div className="info-movimiento acreditar">{formValues ? transformDate(formValues.fecha) : ''}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <FormButton
                    data={data}
                    openAlert={openAlert}
                    inRequest={inRequest}
                    setOpenAlert={setOpenAlert}
                    handleAction={handleAction}
                />
            </form >
        </div >
    );
};