import React, { useState, useEffect } from "react";
// utils
import FormButton from "../../button/FormButton";
import { FormikDateInput, FormikNumberInput, FormikSelectInput } from '../../inputs/FormikInputs';
import CommonForm from "../CommonForm";
// functions
import { postLimite } from './LimitesFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './LimitesFormValidaciones';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState'

export default function LimitesForm(props) {
    const [newLimite, setNewLimite] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede, condiciones } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Addone') {
            const response = await postLimite(credentials, newLimite, setInRequest)
            return handleResponse(response, 'Límite añadido exitosamente', functions)
        }
    };

    useEffect(() => {
        if (procede !== 'Deleteone') handleAction();
    }, [newLimite])

    const formik = formikValidation(setNewLimite, selected.row, titles, condiciones);
    const disabled = procede === 'Deleteone' || inRequest;

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikDateInput 
                data={data}
                disabled={procede !== 'Addone' || disabled}
                name="fecha"
                formik={formik}
            />
            <FormikSelectInput
                data={data}
                disabled={procede !== 'Addone' || disabled}
                name="condicionAFIP"
                formik={formik}
                options={condiciones}
                optionKey="condicionAFIPId"
                optionValue="condicionAFIPId"
                optionName="condicionAFIPNombre"
            />
            <div className="form__input-row">
                <FormikNumberInput
                    data={data}
                    disabled={disabled}
                    name="diaMonto"
                    formik={formik}
                    className="column"
                />
                <FormikNumberInput
                    data={data}
                    disabled={disabled}
                    name="diaCantidad"
                    formik={formik}
                    className="column"
                />
            </div>
            <div className="form__input-row">
                <FormikNumberInput
                    data={data}
                    disabled={disabled}
                    name="mesMonto"
                    formik={formik}
                    className="column"
                />
                <FormikNumberInput
                    data={data}
                    disabled={disabled}
                    name="mesCantidad"
                    formik={formik}
                    className="column"
                />
            </div>
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};