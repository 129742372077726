import axiosPost from '../axiosPost';

export const limitesIns = async (arg) => {
    const { condicionAFIP, diaMonto, diaCantidad, mesMonto, mesCantidad, fecha } = arg;

    const body = {
        "service": "CondicionAFIPLimiteIns",
        "params": {
            "condicionAFIPLimiteCondicionAFIPId": condicionAFIP,
            "fecha": fecha,
            "condicionAFIPLimiteDiaMonto": diaMonto,
            "condicionAFIPLimiteDiaCantidad": diaCantidad,
            "condicionAFIPLimiteMesMonto": mesMonto,
            "condicionAFIPLimiteMesCantidad": mesCantidad
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    }
    catch (err) {
        console.log(err)
    };
}