import axios from 'axios';
import { fallbackURL } from '../../consts/FallbackURL';
import { serviceURL } from '../../consts/ServiceURL';

const axiosPost = async (loginReq, body, arg) => {
    let result
    let headers = {
        'Content-Type': 'application/json'
    }

    if (loginReq === 'Y') {
        headers['x-hooli-token'] = arg.hooliToken
        headers['x-session-id'] = arg.sessionId
    }

    try {
        result = await axios.post(serviceURL, body, { headers })
    } catch (err) {
        return {
            status: {
                code: 0,
                errcode: err.response ? err.response.status : 0,
                errmsg: err.message,
                action: 'U',
                id: null
            },
            result: []
        }
    }

    if (!result || result === null || result === undefined) {
        return {
            status: {
                code: 0,
                errcode: 99999,
                errmsg: 'Error inesperado en request',
                action: 'U',
                id: null
            },
            result: []
        }
    }

    if (result.data.status.errmsg === "Su sesión ha expirado por falta de actividad") {
        localStorage.removeItem('hooliToken')
        localStorage.removeItem('sessionId')
        
        return window.location.assign(fallbackURL);
    };

    return result
};

export default axiosPost;