import React, { useState, useEffect } from 'react';
// styles
import "../pages.css"
// components
import Filtros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/TarjetasGrid";
import TransitionModal from "../../components/utils/modals/Modals";
// Const
import { modalsInfoTransacciones } from "../../consts/Modals-info";
import { liquidacionesPrisma as titles } from '../../consts/titulos-de-tablas';
import { serviceURL } from '../../consts/ServiceURL';
//Services 
import { tarjetasTransaccionesGet } from '../../services/hooli-services/TarjetasTransacciones/TarjetasTransaccionesGet';
import { retrieveData, transformDate } from '../../services/Local-services';
import { merchantsGet } from '../../services/hooli-services/Merchants/MerchantsGet';
import { tarjetasMarcasGet } from '../../services/hooli-services/TarjetasMarcas/TarjetasMarcasGet';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
// recoil 
import { useRecoilValue, useRecoilState } from 'recoil';
import { rowSelected, userCredentials } from '../../recoilState/GlobalState';

const date = new Date();
const lastWeek = new Date();
lastWeek.setDate(lastWeek.getDate() - 7);

export default function LiquidacionesPrisma({ route }) {
    const [transacciones, setTransacciones] = useState([]);
    const [merchants, setMerchants] = useState([]);
    const [merchantSeleccionado, setMerchantSeleccionado] = useState('todos')
    const [tarjetasMarcas, setTarjetasMarcas] = useState([]);
    const [marcaSeleccionada, setMarcaSeleccionada] = useState('todas');
    const [filtros, setFiltros] = useState({
        desde: lastWeek.toISOString().substring(0, 10),
        hasta: date.toISOString().substring(0, 10)
    });
    const [rows, setRows] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [row, setRow] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    async function retrieveTransacciones() {
        const { sessionId, hooliToken } = credentials;

        const params = {
            "fechaDesde": filtros.desde,
            "fechaHasta": filtros.hasta
        };

        const payload = { sessionId, hooliToken, serviceURL, params };
        setRows([]);
        setCargando(true);

        const data = await tarjetasTransaccionesGet(payload)

        setTransacciones(data)
        setCargando(false)
    };

    const retrieveMerchants = () => retrieveData(credentials, undefined, merchantsGet, setMerchants);
    const retrieveMarcasDeTarjetas = () => retrieveData(credentials, undefined, tarjetasMarcasGet, setTarjetasMarcas);

    useEffect(() => {
        if (Object.keys(credentials).length > 0) {
            retrieveMerchants()
            retrieveMarcasDeTarjetas()
            retrieveTransacciones()
        }
    }, [credentials, filtros]);

    useEffect(() => {
        if (transacciones.length > 0) {
            transacciones.map((item) => {
                const {
                    gwcardTransaccionCantidadCuotas,
                    gwcardTransaccionEmisorNombre,
                    gwcardTransaccionEstado,
                    gwcardTransaccionFechaAcreditacion,
                    gwcardTransaccionFechaAutorizacion,
                    gwcardTransaccionFechaEmision,
                    gwcardTransaccionFechaBaja,
                    gwcardTransaccionId,
                    gwcardTransaccionMarcaNombre,
                    gwcardTransaccionMarcaTipo,
                    gwcardTransaccionMerchantNombre,
                    gwcardTransaccionMontoVenta,
                    gwcardTransaccionMonto,
                    gwcardTransaccionOrigenDatosTarjeta,
                    gwcardTransaccionPresente,
                    gwcardTransaccionUsuarioLinkNroDeDocumento,
                    gwcardTransaccionUsuarioNombre,
                } = item;

                const estado = () => {
                    switch (gwcardTransaccionEstado) {
                        case 'P':
                            return 'Pendiente'
                        case 'A':
                            return 'Autorizado'
                        case 'C':
                            return 'Acreditado'
                        case 'V':
                            return 'Conciliado'
                        case 'X':
                            return 'Anulado'
                        case 'R':
                            return 'Cancelado'
                        case 'E':
                            return 'En revision'
                        default:
                            return '--'
                    }
                }

                const transaccion = {
                    id: gwcardTransaccionId,
                    [titles[0]]: gwcardTransaccionFechaEmision ? `${transformDate(gwcardTransaccionFechaEmision.substring(0, 10))} ${gwcardTransaccionFechaEmision.substring(10)}` : '',
                    [titles[1]]: gwcardTransaccionFechaAutorizacion ? `${transformDate(gwcardTransaccionFechaAutorizacion.substring(0, 10))} ${gwcardTransaccionFechaAutorizacion.substring(10)}` : '',
                    [titles[2]]: gwcardTransaccionFechaAcreditacion ? transformDate(gwcardTransaccionFechaAcreditacion.substring(0, 10)) : '',
                    [titles[3]]: gwcardTransaccionFechaBaja ? transformDate(gwcardTransaccionFechaBaja.substring(0, 10)) : '',
                    [titles[4]]: gwcardTransaccionMerchantNombre,
                    [titles[5]]: gwcardTransaccionMontoVenta,
                    [titles[6]]: gwcardTransaccionMonto,
                    [titles[7]]: gwcardTransaccionMarcaNombre,
                    [titles[8]]: gwcardTransaccionUsuarioLinkNroDeDocumento,
                    [titles[9]]: gwcardTransaccionUsuarioNombre,
                    [titles[10]]: gwcardTransaccionPresente === 'Y' ? 'Si' : 'No',
                    [titles[11]]: gwcardTransaccionMarcaTipo === 'C' ? 'Crédito' : 'Débito',
                    [titles[12]]: gwcardTransaccionOrigenDatosTarjeta === 'M' ? 'Manual' : 'Microblink',
                    [titles[13]]: gwcardTransaccionEmisorNombre,
                    [titles[14]]: gwcardTransaccionCantidadCuotas,
                    [titles[15]]: estado()
                };

                setRows(rows => [...rows, transaccion])
                return item
            })
        }
    }, [transacciones])

    useEffect(() => setRow({ status: false, row: {} }), []);


    const gridContainer = document.getElementById('grid-container')
    const gridWidth = gridContainer ? gridContainer.offsetWidth : 0;

    return (
        <>
            <main>
                <Filtros
                    setFiltros={setFiltros}
                    transacciones={transacciones}
                    merchants={merchants}
                    merchantSeleccionado={merchantSeleccionado}
                    setMerchantSeleccionado={setMerchantSeleccionado}
                    tarjetasMarcas={tarjetasMarcas}
                    marcaSeleccionada={marcaSeleccionada}
                    setMarcaSeleccionada={setMarcaSeleccionada}
                    cargando={cargando}
                    updateList={retrieveTransacciones}
                />
                <div className="row-grid" id="grid-container">
                    {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} indice={0} width={gridWidth} />}
                </div>
            </main>
        </>
    )
};