import React, { useEffect, useState } from "react";
import styles from './DetalleModal.module.css';
// Components
import { Button } from "@material-ui/core";
import CircularIndeterminate from "../../components/utils/spinner/Spinner";
import XGridDemo from "../../components/utils/grid/Xgrid";
// Const
import { acreditacionDetalle as titles } from '../../consts/titulos-de-tablas';
// Services
import { tarjetasAcreditacionesDetalleGet } from '../../services/hooli-services/TarjetasAcreditaciones/TarjetasAcreditacionesDetalleGet';
// Recoil
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { formIsOpen,  userCredentials } from '../../recoilState/GlobalState';

export default function DetalleModal({ props }) {
    const setOpen = useSetRecoilState(formIsOpen);
    const credentials = useRecoilValue(userCredentials);

    const { filtros, selected } = props;

    const [detalle, setDetalle] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        (async () => {
            setCargando(true);
            const payload = { ...credentials, fecha: filtros.fecha, merchantId: selected[0].auto }
            const data = await tarjetasAcreditacionesDetalleGet(payload);
            setDetalle(data);
            setCargando(false);
        })()
    }, [selected]);

    useEffect(() => {
        detalle.map((item, index) => {
            const {
                gwcardTransaccionCupon,
                gwcardTransaccionFecha,
                gwcardTransaccionLote,
                gwcardTransaccionMontoVenta,
                fecha_operacion,
                fee_hooli,
                fee_transaccion,
                iva_ret,
                ig_ret,
                ib_ret,
                fee_cuotas,
                iva,
                iva_cuotas,
                dbcr
            } = item;

            const acreditacion = {
                id: index,
                [titles[0]]: fecha_operacion,
                [titles[1]]: gwcardTransaccionLote,
                [titles[2]]: gwcardTransaccionCupon,
                [titles[3]]: gwcardTransaccionMontoVenta,
                [titles[4]]: fee_hooli,
                [titles[5]]: fee_transaccion,
                [titles[6]]: fee_cuotas,
                [titles[7]]: iva,
                [titles[8]]: iva_cuotas,
                [titles[9]]: dbcr,
                [titles[10]]: gwcardTransaccionMontoVenta - fee_hooli - fee_transaccion - fee_cuotas - iva - iva_cuotas - dbcr,
            };

            setRows(rows => [...rows, acreditacion])
            return item
        });
    }, [detalle]);

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Detalle - {selected[0].gwcardTransaccionMerchantNombre}</h2>
            <div id="grid-detalle-acreditacion">
                {cargando ? <CircularIndeterminate /> :
                    <XGridDemo
                        rows={rows}
                        titles={titles}
                        secondarySelected={{}}
                        setSecondarySelected={() => { }}
                        procede="detalle"
                    />
                }
            </div>
            <div className={styles.buttonContainer}>
                <Button className={styles.buttonCerrar} onClick={() => setOpen(false)}>
                    Cerrar
                </Button>
            </div>
        </div>
    )
};