import { useFormik as formik } from "formik";

export const formikValidation = (setNewLimite, row) => formik({
    initialValues: {
        diaMonto: row && row.diaLimiteMonto ? row.diaLimiteMonto : 1000,
        diaCantidad: row && row.diaLimiteCantidad ? row.diaLimiteCantidad : 1000,
        mesMonto: row && row.mesLimiteMonto ? row.mesLimiteMonto : 1000,
        mesCantidad: row && row.mesLimiteCantidad ? row.mesLimiteCantidad : 1000
    },
    onSubmit: (values) => setNewLimite(values)
});