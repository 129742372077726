import axiosPost from '../axiosPost';

export const tarjetasEmisoresGet = async (arg) => {
    const body = {
        "service": "GWCardEmisorGet",
        "params": {}
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data.result
    } 
    catch (err) {
        console.log(err)
    };
}