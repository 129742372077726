import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import HeaderButton from "../../components/utils/button/HeaderButton";
// icons
import reload from "../../assets/icons/actualizar.svg";
// form
import * as yup from "yup";
import { useFormik } from "formik";

function Filtros(props) {
    const { transacciones, setFiltros, merchants, merchantSeleccionado, setMerchantSeleccionado, tarjetasMarcas, marcaSeleccionada, setMarcaSeleccionada, cargando, updateList } = props;

    const date = new Date();
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);

    const validationSchema = yup.object({
        hasta: yup
            .date()
            .max(date.toISOString().substring(0, 10), "Fecha maxima hoy")
            .required("Por favor seleccione una fecha"),
        desde: yup
            .date()
            .max(yup.ref('hasta'), "No puede ser posterior a la segunda fecha")
            .required("Por favor seleccione una fecha"),
    });

    const formik = useFormik({
        initialValues: {
            desde: lastWeek.toISOString().substring(0, 10),
            hasta: date.toISOString().substring(0, 10),
        },
        validationSchema: validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    return (
        <form className="container-select-modelos" onSubmit={formik.handleSubmit}>
            <div className="selects">
                <TextField
                    label="Desde"
                    name="desde"
                    className="select-modelos select-conciliacion"
                    style={{ marginRight: '2em' }}
                    type="date"
                    value={formik.values.desde}
                    onChange={formik.handleChange}
                    error={formik.touched.desde && Boolean(formik.errors.desde)}
                    helperText={formik.touched.desde && formik.errors.desde}
                    InputLabelProps={{ shrink: false }}
                />
                <TextField
                    label="Hasta"
                    name="hasta"
                    className="select-modelos select-conciliacion"
                    style={{ marginRight: '2em' }}
                    type="date"
                    value={formik.values.hasta}
                    onChange={formik.handleChange}
                    error={formik.touched.hasta && Boolean(formik.errors.hasta)}
                    helperText={formik.touched.hasta && formik.errors.hasta}
                    InputLabelProps={{ shrink: false }}
                />
                <Button
                    type="submit"
                    style={{
                        width: "6rem",
                        letterSpacing: "0rem",
                        height: "2rem",
                        color: "#ffffff",
                        opacity: 1,
                        background: "linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)",
                        borderRadius: 21,
                        marginRight: "2rem",
                        fontWeight: 300,
                        fontFamily: '"Roboto", sans-serif',
                        "&:hover": {
                            background: "linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%)",
                        },
                        "&:disabled": {
                            background: "#A7A7A7",
                        }
                    }}
                >
                    Filtrar
                </Button>
            </div>
            <div className="icons">
                <HeaderButton
                    text="Actualizar pagina"
                    icon={reload}
                    updateList={updateList}
                />
            </div>
        </form>
    )
};

export default Filtros