import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from "../../components/utils/buttonsContainer/Buttons";
// consts
import { feriados as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoFeriados as infoModal } from "../../consts/Modals-info";
import addIcon from "../../assets/icons/rediseño.svg";
import reload from "../../assets/icons/actualizar.svg";
import editar from "../../assets/icons/editar.svg";
import eliminar from "../../assets/icons/eliminar.svg";
import HeaderButton from "../../components/utils/button/HeaderButton";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { feriadosGet } from '../../services/hooli-services/Feriados/FeriadosGet';
// recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { rowSelected, userCredentials, formData } from '../../recoilState/GlobalState';

export default function Feriados() {
    const [feriados, setFeriados] = useState([]);
    const [rows, setRows] = useState([]);
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(false);

    const [row, setRow] = useRecoilState(rowSelected)
    const setFormInfo = useSetRecoilState(formData)
    const credentials = useRecoilValue(userCredentials);

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, feriadosGet, setFeriados)
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList()
    }, [credentials]);

    useEffect(() => {
        feriados.map((item) => {
            const {
                feriadoId,
                feriadoNombre,
                feriadoVersion,
            } = item

            const feriado = {
                id: feriadoId,
                version: feriadoVersion,
                [titles[0]]: feriadoNombre,
            }

            setRows(rows => [...rows, feriado])
            return item
        })
    }, [feriados]);

    useEffect(() => setRow({ status: false, row: {}}), []);


    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action])
        setAction({ action, procede });
    };

    return (
        <main>
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
            />
            <Buttons>
                {row.status ?
                    <>
                        <HeaderButton
                            text={"Editar feriado"}
                            icon={editar}
                            handleClick={handleClick}
                            data={{ action: 'edit', procede: 'Editone' }}
                        />
                        <HeaderButton
                            text={"Eliminar feriado"}
                            icon={eliminar}
                            handleClick={handleClick}
                            data={{ action: 'delete', procede: 'Deleteone' }}
                        />
                    </>
                    :
                    <>
                        <HeaderButton
                            text={"Agregar feriado"}
                            icon={addIcon}
                            handleClick={handleClick}
                            data={{ action: 'add', procede: 'Addone' }}
                        />
                        <HeaderButton
                            text={"Actualizar pagina"}
                            icon={reload}
                            updateList={updateList}
                        />
                    </>
                }
            </Buttons>
            <div className="row-grid" id="grid-container">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};