import React from 'react';
import { FiltrosStyles } from '../Filtros.styles';
// assets
import reload from "../../assets/icons/actualizar.svg";
import editar from "../../assets/icons/editar.svg";
// Components
import HeaderButton from "../../components/utils/button/HeaderButton";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import { FormikDateInput, FormikSelectInput } from '../../components/utils/inputs/FormikInputs';
// form
import { useFormik } from 'formik';
import * as yup from 'yup';
// recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../recoilState/GlobalState';
import SubmitButton from '../../components/utils/button/SubmitButton';

export default function Filtros(props) {
    const { status } = useRecoilValue(rowSelected);

    const { filtros, setFiltros, handleClick, cargando, updateList } = props;

    const classes = FiltrosStyles();

    const validationSchema = yup.object({
        entidad: yup.string().required("Seleccione una opción"),
        fecha: yup.date().max(new Date().toISOString().substring(0, 10), "No puede ser una fecha futura").required("Seleccione una fecha")
    });

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const data = { entidad: { label: 'Entidad' }, fecha: { label: 'Fecha' } };
    const entidades = [{ entidadCod: 'U', entidadName: 'Usuario' }, { entidadCod: 'M', entidadName: 'Merchant' }];

    return (
        <div className={classes.root}>
            <form className={classes.filtros} onSubmit={formik.handleSubmit}>
                <FormikSelectInput
                    className={classes.input}
                    disabled={cargando}
                    name="entidad"
                    formik={formik}
                    data={data}
                    optionKey="entidadCod"
                    optionValue="entidadCod"
                    optionName="entidadName"
                    options={entidades}
                />
                <FormikDateInput
                    className={classes.input}
                    disabled={cargando}
                    name="fecha"
                    formik={formik}
                    data={data}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Buscar" />
                </div>
            </form>
            <Buttons>
                {status ?
                    <HeaderButton
                        text='Editar limite'
                        icon={editar}
                        handleClick={handleClick}
                        data={{ action: 'edit', procede: 'Editone' }}
                    />
                    :
                    <HeaderButton
                        text={"Actualizar pagina"}
                        icon={reload}
                        updateList={updateList}
                    />
                }
            </Buttons>
        </div>
    )
};