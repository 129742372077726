import axiosPost from '../axiosPost';

export const tarjetasConciliacionesUpd = async (arg) => {
    const { ids } = arg

    const body = {
        "service": "GWCardTransaccionConciliar",
        "params": {
            "gwcardTransaccionIds": ids
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
}