import React, { useState } from "react";
// utils
import FormButton from "../../button/FormButton";
import TextField from "@material-ui/core/TextField"
import CommonForm from "../CommonForm";
import { FormikTextInput } from "../../inputs/FormikInputs";
// functions
import { postMarca } from './MarcasFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './MarcasFormValidaciones';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState';

export default function MarcasForm(props) {
    const [newMarca, setNewMarca] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData)
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };

        const response = await postMarca(credentials, newMarca, setInRequest)
        return handleResponse(response, 'Marca añadida exitosamente', functions)
    };

    const formik = formikValidation(setNewMarca, selected.row, titles);

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikTextInput
                data={data}
                name='nombre'
                formik={formik}
                disabled={inRequest}
            />
            <TextField
                label={data.tipo.label}
                name='tipo'
                placeholder={data.tipo.placeholder}
                margin="normal"
                select
                disabled={inRequest}
                SelectProps={{ native: true }}
                value={formik.values.tipo}
                onChange={formik.handleChange}
                error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                helperText={formik.touched.tipo && formik.errors.tipo}
                fullWidth
            >
                <option value="C">Crédito</option>
                <option value="D">Débito</option>
            </TextField>
            <FormikTextInput
                data={data}
                name='codigo'
                formik={formik}
                disabled={inRequest}
            />
            <FormikTextInput
                data={data}
                name='marcaCod'
                formik={formik}
                disabled={inRequest}
            />
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};