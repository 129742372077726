import axiosPost from '../axiosPost';

export const tarjetasCoeficientesUpd = async (arg) => {
    const { params } = arg

    const body = {
        "service": "GWCardCoeficienteUpd",
        "params": params
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
}