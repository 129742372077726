// services
import { limitesUpd } from '../../../../services/hooli-services/Limites/UsuarioMerchantLimiteUpd';

export async function updateLimite(credentials, newLimite, row, setInRequest) {
    if (Object.keys(newLimite).length > 0) {
        const { userId, version, entidad } = row;

        const payload = {
            id: userId,
            ...newLimite,
            version,
            entidad,
            ...credentials
        };

        setInRequest(true);
        const response = await limitesUpd(payload)
        return response
    }
};