import React, { useState, useEffect } from 'react';
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/TarjetasGrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import HeaderButton from "../../components/utils/button/HeaderButton";
// Const
import { modalsInfoEmisores as infoModal } from "../../consts/Modals-info";
import { emisores as titles } from '../../consts/titulos-de-tablas';
//Services 
import { retrieveData } from '../../services/Local-services';
import { tarjetasEmisoresGet } from '../../services/hooli-services/TarjetasEmisores/TarjetasEmisoresGet';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
import addIcon from "../../assets/icons/rediseño.svg";
import reload from "../../assets/icons/actualizar.svg";
import editar from "../../assets/icons/editar.svg";
import eliminar from "../../assets/icons/eliminar.svg";
// recoil 
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formData, rowSelected, userCredentials } from '../../recoilState/GlobalState';

export default function TarjetasEmisores() {
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(true);
    const [emisores, setEmisores] = useState([]);
    const [rows, setRows] = useState([]);

    const [{ status }, setRow] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData)

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, tarjetasEmisoresGet, setEmisores);
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList() 
    }, [credentials]);

    useEffect(() => {
        emisores.map((item) => {
            const {
                gwcardEmisorId,
                gwcardEmisorVersion,
                gwcardEmisorNombre,
                gwcardEmisorCod,
                gwcardEmisorTipo
            } = item

            const emisor = {
                id: gwcardEmisorId,
                version: gwcardEmisorVersion,
                bancario: gwcardEmisorTipo,
                [titles[0]]: gwcardEmisorNombre,
                [titles[1]]: gwcardEmisorCod,
                [titles[2]]: gwcardEmisorTipo === 'B' ? 'Bancario' : 'No bancario'
            };

            setRows(rows => [...rows, emisor])
            return item
        });
    }, [emisores])

    useEffect(() => setRow({ status: false, row: {} }), []);

    const handleClick = ({ action, procede }) => {
        setAction({ action, procede });
        setFormInfo(infoModal[action]);
    };

    const gridContainer = document.getElementById('grid-container')
    const gridWidth = gridContainer ? gridContainer.offsetWidth : 0;

    return (
        <main>
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
            />
            <Buttons>
                {status ?
                    <>
                        <HeaderButton
                            text={"Editar emisor de tarjeta"}
                            icon={editar}
                            handleClick={handleClick}
                            data={{ action: 'edit', procede: 'Editone' }}
                        />
                        <HeaderButton
                            text={"Eliminar emisor de tarjeta"}
                            icon={eliminar}
                            handleClick={handleClick}
                            data={{ action: 'delete', procede: 'Deleteone' }}
                        />
                    </>
                    :
                    <>
                        <HeaderButton
                            text={"Agregar emisor de tarjeta"}
                            icon={addIcon}
                            handleClick={handleClick}
                            data={{ action: 'add', procede: 'Addone' }}
                        />
                        <HeaderButton
                            text={"Actualizar pagina"}
                            icon={reload}
                            updateList={updateList}
                        />
                    </>
                }
            </Buttons>
            <div className="row-grid" id="grid-container">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} indice={0} width={gridWidth} />}
            </div>
        </main>
    )
};