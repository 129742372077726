import axiosPost from '../axiosPost';

export const conciliacionesReverso = async (arg) => {
    const { ids, tipo } = arg

    const body = {
        "service": "BindConciliacionReversoDo",
        "params": {
            "bindConciliacionOperacionIds": ids,
            "bindConciliacionTipoOperacion": tipo
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        console.log(body, result.data);
        return result.data
    }
    catch (err) {
        console.log(err)
    };
};