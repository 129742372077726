import React from 'react';
import TextField from "@material-ui/core/TextField";
import SubmitButton from '../../components/utils/button/SubmitButton';
// form
import * as yup from "yup";
import { useFormik } from "formik";

export default function Filtros(props) {
    const { setFiltros, tiposDeDocumento } = props;

    const validationSchema = yup.object({
        categoria: yup.string().required('Seleccione una opcion'),
        tipoDeDocumento: yup.string().required('Seleccione una opcion'),
        cuitDNI: yup.string().required()
    });

    const formik = useFormik({
        initialValues: {
            categoria: 'EU',
            tipoDeDocumento: 1,
            cuitDNI: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    return (
        <form className="container-select-modelos" onSubmit={formik.handleSubmit}>
            <div className="selects">
                <TextField
                    label="Categoria"
                    name="categoria"
                    className="select-modelos select-conciliacion"
                    style={{ marginRight: '2em' }}
                    select
                    SelectProps={{ native: true }}
                    value={formik.values.categoria}
                    onChange={formik.handleChange}
                    error={formik.touched.categoria && Boolean(formik.errors.categoria)}
                    helperText={formik.touched.categoria && formik.errors.categoria}
                    InputLabelProps={{ shrink: false }}
                >
                    <option value="M">Merchant</option>
                    <option value="EU">End User</option>
                </TextField>

                {formik.values.categoria === 'EU' &&
                    <TextField
                        label="Tipo de documento"
                        name="tipoDeDocumento"
                        className="select-modelos"
                        style={{ marginRight: '2em' }}
                        select
                        SelectProps={{ native: true }}
                        value={formik.values.tipoDeDocumento}
                        onChange={formik.handleChange}
                        error={formik.touched.tipoDeDocumento && Boolean(formik.errors.tipoDeDocumento)}
                        helperText={formik.touched.tipoDeDocumento && formik.errors.tipoDeDocumento}
                        InputLabelProps={{ shrink: false }}
                    >
                        {tiposDeDocumento.map(tipo => {
                            return <option value={tipo.tipoDeDocumentoId} key={tipo.tipoDeDocumentoId}>{tipo.tipoDeDocumentoNombre}</option>
                        })}
                    </TextField>
                }

                <TextField
                    label={formik.values.categoria === 'M' ? 'CUIT' : 'DNI'}
                    name="cuitDNI"
                    className="select-modelos select-conciliacion"
                    style={{ marginRight: '2em' }}
                    value={formik.values.cuitDNI}
                    onChange={formik.handleChange}
                    error={formik.touched.cuitDNI && Boolean(formik.errors.cuitDNI)}
                    helperText={formik.touched.cuitDNI && formik.errors.cuitDNI}
                    InputLabelProps={{ shrink: false }}
                />

                <SubmitButton texto='Buscar' />
            </div>
        </form>
    )
};