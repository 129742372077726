import axiosPost from '../axiosPost';

export const tarjetasEmisoresUpd = async (arg) => {
    const { id, nombre, codigo, bancario, version } = arg;

    const body = {
        "service": "GWCardEmisorUpd",
        "params": {
            "gwcardEmisorId": id,
            "gwcardEmisorNombre": nombre,
            "gwcardEmisorCod": codigo,
            "gwcardEmisorTipo": bancario,
            "gwcardEmisorVersion": version
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
}