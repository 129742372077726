import axiosPost from '../axiosPost';

export const tarjetasPlasticosUpd = async (arg) => {
    const { id, emisor, codigo, version } = arg

    const body = {
        "service": "GWCardPlasticoUpd",
        "params": {
            "gwcardPlasticoId": id,
            "gwcardPlasticoCodigoLine": codigo,
            "gwcardEmisorCod": emisor,
            "gwcardPlasticoVersion": version
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
}