import React, { useState, useEffect } from 'react';
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/AcreditacionesGrid";
import Filtros from './Filtros';
// Const
import { tarjetasConciliaciones as titles } from '../../consts/titulos-de-tablas';
//Services 
import { handleResponse, transformDate } from '../../services/Local-services';
import { tarjetasConciliacionesUpd } from '../../services/hooli-services/TarjetasConciliaciones/TarjetasConciliacionesUpd';
import { tarjetasTransaccionesAConciliarGet } from '../../services/hooli-services/TarjetasConciliaciones/TarjetasConciliacionesGet';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
// recoil 
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formIsOpen, snackbarData, userCredentials } from '../../recoilState/GlobalState';

const lastWeek = new Date();
lastWeek.setDate(lastWeek.getDate() - 7);

export default function TarjetasConciliaciones() {
    const [action, setAction] = useState({});
    const [transacciones, setTransacciones] = useState([]);
    const [filtros, setFiltros] = useState({
        desde: lastWeek.toISOString().substring(0, 10),
        hasta: new Date().toISOString().substring(0, 10)
    });
    const [rows, setRows] = useState([]);
    const [selected, setSelected] = useState([]);
    const [cargando, setCargando] = useState(true);

    const credentials = useRecoilValue(userCredentials);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setIsOpen = useSetRecoilState(formIsOpen);

    async function retrieveTransacciones() {
        const params = {
            "fechaDesde": filtros.desde,
            "fechaHasta": filtros.hasta,
        };

        const payload = { ...credentials, params };
        setRows([]);
        setSelected([]);
        setCargando(true);

        const data = await tarjetasTransaccionesAConciliarGet(payload);

        setTransacciones(data)
        setCargando(false)
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveTransacciones()
    }, [credentials, filtros]);

    useEffect(() => {
        transacciones.map((item) => {
            const {
                gwcardTransaccionId,
                gwcardTransaccionFechaAutorizacion,
                gwcardTransaccionMonto,
                gwcardTransaccionLote,
                gwcardTransaccionCupon,
                gwcardTransaccionUltimos4
            } = item;

            const transaccion = {
                id: gwcardTransaccionId,
                [titles[0]]: gwcardTransaccionFechaAutorizacion ? `${transformDate(gwcardTransaccionFechaAutorizacion.substring(0, 10))} ${gwcardTransaccionFechaAutorizacion.substring(10)}` : '',
                [titles[1]]: gwcardTransaccionMonto,
                [titles[2]]: gwcardTransaccionLote,
                [titles[3]]: gwcardTransaccionCupon,
                [titles[4]]: gwcardTransaccionUltimos4
            };

            setRows(rows => [...rows, transaccion])
            return item
        })
    }, [transacciones])

    const handleClick = ({ action, procede }) => setAction({ action, procede });

    useEffect(() => {
        const functions = {
            setSnackbarInfo,
            setInRequest: setCargando,
            setIsOpen,
            setSelected,
            updateList: retrieveTransacciones
        };
        (async () => {
            if (action.action === 'noOpen') {
                const ids = selected.reduce((acc, row) => acc + row.id + ',', '');

                const payload = { ...credentials, ids: ids.substring(0, ids.length - 1) };
                const response = await tarjetasConciliacionesUpd(payload);
                handleResponse(response, 'Conciliado exitosamente', functions)
                setSelected([]);
            }
        })();
    }, [action]);

    const gridContainer = document.getElementById('grid-container')
    const gridWidth = gridContainer ? gridContainer.offsetWidth : 0;

    return (
        <main>
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                selected={selected}
                handleClick={handleClick}
                updateList={retrieveTransacciones}
                cargando={cargando}
            />
            <div className="row-grid" id="grid-container">
                {cargando ? <CircularIndeterminate /> :
                    <XGridDemo rows={rows} titles={titles} selected={selected} setSelected={setSelected} width={gridWidth} />}
            </div>
        </main>
    )
};