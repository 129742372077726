import axiosPost from '../axiosPost';

export const tarjetasCoeficientesIns = async (arg) => {
    const { id, cuotas, valor, vigencia, tipo } = arg

    const body = {
        "service": "GWCardCoeficienteIns",
        "params": {
            "gwcardCoeficienteMarcaId": id,
            "gwcardCoeficienteCantidadCuotas": cuotas,
            "gwcardCoeficienteValor": valor,
            "gwcardCoeficienteDesde": vigencia,
            "gwcardCoeficienteTipo": tipo
        }
    }
    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
}