import axiosPost from '../axiosPost';

export const tarjetasEmisoresIns = async (arg) => {
    const { nombre, codigo, bancario } = arg;

    const body = {
        "service": "GWCardEmisorIns",
        "params": {
            "gwcardEmisorNombre": nombre,
            "gwcardEmisorCod": codigo,
            "gwcardEmisorTipo": bancario,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    }
    catch (err) {
        console.log(err)
    }
};