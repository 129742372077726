import axiosPost from '../axiosPost';

export const tarjetasTransaccionesAConciliarGet = async (arg) => {
    const { params } = arg;

    const body = {
        "service": "GWCardTransaccionAConciliarGet",
        "params": params
    }

    try {
        const result = await axiosPost('Y', body, arg)
        console.log(result.data)
        return result.data.result
    } catch (err) {
        console.log(err)
    }
}