import React, { useState, useEffect } from 'react';
// styles
import "../pages.css";
// components
import Header from "../../components/header/Header";
import { TextField } from '@material-ui/core';
import SubmitButton from '../../components/utils/button/SubmitButton';
// utils
import Filtros from './Filtros';
import TransitionModal from '../../components/utils/modals/Modals';
// Const
import { formInfoPromosMarketing as formData, modalsInfoPromosMarketing } from "../../consts/Modals-info";
import { serviceURL } from '../../consts/ServiceURL';
//Services 
import { generateDateInput, generateTextInput, retrieveData } from '../../services/Local-services';
import { tiposDeDocumentoGet } from '../../services/hooli-services/TiposDeDocumento/TiposDeDocumentoGet';
import { merchantsGet } from '../../services/hooli-services/Merchants/MerchantsGet';
import { usuarioGet } from '../../services/hooli-services/Usuario/UsuarioGet';
import { useFormik } from 'formik';
import * as yup from "yup";
// recoil 
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formIsOpen, userCredentials } from '../../recoilState/GlobalState';

export default function PromosMarketing({ route }) {
    const [filtros, setFiltros] = useState({});
    const [tiposDeDocumento, setTiposDeDocumento] = useState([]);
    const [usuario, setUsuario] = useState();
    const [cargando, setCargando] = useState(false);
    const [formValues, setFormValues] = useState();

    const setOpen = useSetRecoilState(formIsOpen);

    const credentials = useRecoilValue(userCredentials);

    async function retrieveUsuario() {
        const { sessionId, hooliToken } = credentials;

        const params = filtros.categoria === 'M' ?
            {
                "merchantCUIT": filtros.cuitDNI
            } :
            {
                "tipoDeDocumentoId": filtros.tipoDeDocumento,
                "usuarioNroDeDocumento": filtros.cuitDNI
            };

        const payload = { sessionId, hooliToken, serviceURL, params };
        setUsuario();
        setCargando(true);

        const data = filtros.categoria === 'M' ? await merchantsGet(payload) : await usuarioGet(payload);
        setCargando(false);
        if (data[0]) {
            setUsuario(data[0]);
        }
    };

    useEffect(() => {
        retrieveData(credentials, undefined, tiposDeDocumentoGet, setTiposDeDocumento);
    }, []);

    useEffect(() => {
        if (Object.keys(filtros).length > 0) retrieveUsuario()
    }, [filtros]);

    const segundaFecha = new Date().setDate(new Date().getDate() + 10);
    const validationSchema = yup.object({
        hooliCoins: yup.number('Ingrese un numero valido').positive('Ingrese un numero positivo').required('Por favor ingrese un numero'),
        fecha: yup
            .date()
            .min(new Date().toISOString().substring(0, 10), 'Fechas entre hoy y los 10 proximos dias')
            .max(new Date(segundaFecha).toISOString().substring(0, 10), 'Fechas entre hoy y los 10 proximos dias')
            .required('Por favor seleccione una fecha')
    });

    const formik = useFormik({
        initialValues: {
            hooliCoins: 0,
            fecha: new Date().toISOString().substring(0, 10)
        },
        validationSchema,
        onSubmit: (values) => {
            setFormValues(values);
            setOpen(true);
        } 
    });
    return (
        <>
            <TransitionModal
                route={route.wordKey}
                formValues={formValues}
                data={modalsInfoPromosMarketing}
                usuario={usuario}
                categoria={filtros.categoria}
            />
            <main>
                <Filtros
                    setFiltros={setFiltros}
                    tiposDeDocumento={tiposDeDocumento}
                />
                <div className="row-grid">
                    <div className="form-container">
                        {filtros.categoria === 'M' ?
                            <>
                                <TextField
                                    type="text"
                                    label="Nombre"
                                    name="nombre"
                                    value={usuario ? usuario.merchantNombre : ''}
                                    disabled={cargando}
                                    style={{ paddingBottom: '1rem', width: '50%' }}
                                />
                                <TextField
                                    type="text"
                                    label="Razon Social"
                                    name="razonSocial"
                                    value={usuario ? usuario.merchantRZ : ''}
                                    disabled={cargando}
                                    style={{ paddingBottom: '1rem', width: '50%' }}
                                />
                                <TextField
                                    type="text"
                                    label="Persona jurídica o fisica"
                                    name="juridicaFisica"
                                    value={usuario ? usuario.merchantPFPJ === 'F' ? 'Fisica' : 'Juridica' : ''}
                                    disabled={cargando}
                                    style={{ paddingBottom: '1rem', width: '50%' }}
                                />
                                <TextField
                                    type="text"
                                    label="Condición AFIP"
                                    name="condicion"
                                    value={usuario ? usuario.condicionAFIPNombre : ''}
                                    disabled={cargando}
                                    style={{ paddingBottom: '1rem', width: '50%' }}
                                />
                                <TextField
                                    type="text"
                                    label="Rubro"
                                    name="rubro"
                                    value={usuario ? usuario.rubroMerchantNombre : ''}
                                    disabled={cargando}
                                    style={{ paddingBottom: '1rem', width: '50%' }}
                                />
                                <TextField
                                    type="text"
                                    label="Administrador"
                                    name="administrador"
                                    value={usuario ? usuario.condicionAFIPNombre : ''}
                                    disabled={cargando}
                                    style={{ paddingBottom: '1rem', width: '50%' }}
                                />
                            </>
                            :
                            <>
                                <TextField
                                    type="text"
                                    label="Apellidos"
                                    name="apellidos"
                                    value={usuario ? usuario.usuarioApellidos : ''}
                                    disabled={cargando}
                                    style={{ paddingBottom: '1rem', width: '50%' }}
                                />
                                <TextField
                                    type="text"
                                    label="Nombres"
                                    name="nombres"
                                    value={usuario ? usuario.usuarioNombres : ''}
                                    disabled={cargando}
                                    style={{ paddingBottom: '1rem', width: '50%' }}
                                />
                                <TextField
                                    type="text"
                                    label="Es Merchant"
                                    name="esMerchant"
                                    value={usuario ? usuario.usuarioEsMerchant : ''}
                                    disabled={cargando}
                                    style={{ paddingBottom: '1rem', width: '50%' }}
                                />
                                {usuario && usuario.usuarioEsMerchant === 'SI' &&
                                    <>
                                        <TextField
                                            type="text"
                                            label="Nombre"
                                            name="nombre"
                                            value={usuario ? usuario.merchantNombre : ''}
                                            disabled={cargando}
                                            style={{ paddingBottom: '1rem', width: '50%' }}
                                        />
                                        <TextField
                                            type="text"
                                            label="Razon Social"
                                            name="razonSocial"
                                            value={usuario ? usuario.merchantRZ : ''}
                                            disabled={cargando}
                                            style={{ paddingBottom: '1rem', width: '50%' }}
                                        />
                                    </>}
                            </>
                        }
                    </div>
                    <form style={{ marginTop: '2rem' }} onSubmit={formik.handleSubmit}>
                        <div style={{ width: '20%' }}>
                            {/* {generateTextInput(formData, 'hooliCoins', formik, cargando)}
                            {generateDateInput(formData, 'fecha', formik, cargando)} */}
                            <SubmitButton texto="Transferir" />
                        </div>
                    </form>
                </div>
            </main >
        </>
    )
};