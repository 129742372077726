import React, { useState, useEffect } from 'react';
// styles
import "../pages.css"
// components
import Filtros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/ConciliacionGrid";
import TransitionModal from "../../components/utils/modals/Modals";
import { modalsInfoConciliations as infoModal } from "../../consts/Modals-info";
// Const
import { conciliaciones as titles } from '../../consts/titulos-de-tablas';
//Services 
import { conciliacionesGet } from '../../services/hooli-services/Conciliaciones/ConciliacionGet';
import { conciliacionBTGet } from '../../services/hooli-services/Conciliaciones/ConciliacionBTGet';
import { conciliacionHTGet } from '../../services/hooli-services/Conciliaciones/ConciliacionHTGet';
import { update } from './ConciliacionesFunctions';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
// recoil 
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formData, userCredentials } from '../../recoilState/GlobalState';

export default function Conciliaciones() {
    const [conciliaciones, setConciliaciones] = useState([]);
    const [rows, setRows] = useState([]);
    const [filtros, setFiltros] = useState({
        desde: new Date().toISOString().substring(0, 10),
        hasta: new Date().toISOString().substring(0, 10),
        estado: 'todos',
        cuit: '',
        monto: 0
    });
    const [selected, setSelected] = useState([]);
    const [procede, setProcede] = useState('');
    const [cargando, setCargando] = useState(true);
    const [rowInfo, setRowInfo] = useState({});
    const [details, setDetails] = useState({});
    const [movimiento, setMovimiento] = useState('');
    const [modalCargando, setModalCargando] = useState(false);

    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);

    async function retrieveConciliaciones() {
        const params = {
            "fechaDesde": filtros.desde,
            "fechaHasta": filtros.hasta,
            "bindConciliacionEstado": filtros.estado === 'todos' ? null : filtros.estado,
            "bindConciliacionCUIT": filtros.cuit === '' ? null : filtros.cuit,
            "bindConciliacionMonto": filtros.monto === 0 ? null : filtros.monto
        };
        const payload = { ...credentials, params };
        setCargando(true);
        setRows([]);
        setSelected([]);

        const data = await conciliacionesGet(payload);
        setConciliaciones(data)
        setCargando(false)
    };

    async function retrieveDetails() {
        const id = rowInfo ? movimiento === 'H' ? rowInfo.hooliId || rowInfo.origen : rowInfo.bindId : null
        const payload = { ...credentials, id };
        setModalCargando(true);
        const data = movimiento === 'H' ? await conciliacionHTGet(payload) : movimiento === 'B' ? await conciliacionBTGet(payload) : null;
        setDetails(data)
        setModalCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveConciliaciones()
    }, [credentials, filtros]);

    useEffect(() => {
        if (Object.keys(rowInfo).length > 0) retrieveDetails()
    }, [rowInfo, movimiento])

    useEffect(() => {
        if (conciliaciones.length > 0) {
            conciliaciones.map((item, index) => {
                const {
                    bindConciliacionId,
                    bindConciliacionBindCUITOrigen,
                    bindConciliacionBindCUITDestino,
                    bindConciliacionBindCVUDestino,
                    bindConciliacionBindFecha,
                    bindConciliacionBindEstado,
                    bindConciliacionBindId,
                    bindConciliacionBindMonto,
                    bindConciliacionHooliCUITDestino,
                    bindConciliacionHooliCUITOrigen,
                    bindConciliacionHooliFecha,
                    bindConciliacionHooliEstado,
                    bindConciliacionHooliId,
                    bindConciliacionHooliMonto,
                    bindConciliacionTipoMovimiento,
                    bindConciliacionHooliOrigen
                } = item;

                const movimiento = {
                    id: index,
                    estado: bindConciliacionHooliEstado || bindConciliacionBindEstado,
                    hooliId: bindConciliacionHooliId,
                    bindId: bindConciliacionBindId,
                    origen: bindConciliacionHooliOrigen,
                    cvuDestino: bindConciliacionBindCVUDestino,
                    bindConciliacionId,
                    bindConciliacionTipoMovimiento,
                    [titles[0]]: bindConciliacionTipoMovimiento === 'S' ? "Cash out" : bindConciliacionTipoMovimiento === 'E' ? "Cash in" : "Reverso",
                    [titles[1]]: bindConciliacionId ? bindConciliacionId : '',
                    [titles[2]]: bindConciliacionHooliMonto,
                    [titles[3]]: bindConciliacionHooliFecha ? bindConciliacionHooliFecha.substring(0, 10) : null,
                    [titles[4]]: bindConciliacionHooliCUITOrigen,
                    [titles[5]]: bindConciliacionHooliCUITDestino,
                    [titles[6]]: bindConciliacionBindMonto,
                    [titles[7]]: bindConciliacionBindFecha ? bindConciliacionBindFecha.substring(0, 10) : null,
                    [titles[8]]: bindConciliacionBindCUITOrigen,
                    [titles[9]]: bindConciliacionBindCUITDestino
                };
                setRows(rows => [...rows, movimiento])
                return item
            })
        }
    }, [conciliaciones]);

    useEffect(() => setSelected([]), [])

    const handleClick = ({ procede }) => {
        setFormInfo(infoModal[procede])
        setProcede(procede);
        if (procede === 'completar') update(credentials, selected, procede, '', '', '');
    };

    const gridContainer = document.getElementById('grid-container')
    const gridWidth = gridContainer ? gridContainer.offsetWidth : 0;

    return (
        <main>
            <TransitionModal
                procede={procede}
                selected={selected}
                details={details}
                movimiento={movimiento}
                cargando={modalCargando}
                id={rowInfo ? rowInfo.id : null}
                setSelected={setSelected}
                updateList={retrieveConciliaciones}
            />
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                selected={selected}
                handleClick={handleClick}
                updateList={retrieveConciliaciones}
                cargando={cargando}
            />
            <div className="row-grid-bind" id="grid-container">
                {cargando ? <CircularIndeterminate /> :
                    <XGridDemo
                        rows={rows}
                        titles={titles}
                        width={gridWidth}
                        selected={selected}
                        setSelected={setSelected}
                        setProcede={setProcede}
                        setRowInfo={setRowInfo}
                        setMovimiento={setMovimiento}
                    />
                }
            </div>
        </main>
    )
};