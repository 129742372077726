// services
import { tarjetasEmisoresIns } from '../../../../services/hooli-services/TarjetasEmisores/TarjetasEmisoresIns';
import { tarjetasEmisoresDel } from '../../../../services/hooli-services/TarjetasEmisores/TarjetasEmisoresDel';
import { tarjetasEmisoresUpd } from '../../../../services/hooli-services/TarjetasEmisores/TarjetasEmisoresUpd';

export async function postEmisor(credentials, newEmisor, setInRequest) {
    if (Object.keys(newEmisor).length > 0) {
        const { nombre, codigo, bancario } = newEmisor;

        const payload = {
            nombre,
            codigo,
            bancario: bancario === 'Y' ? 'B' : 'N',
            ...credentials
        };

        setInRequest(true);
        const response = await tarjetasEmisoresIns(payload);
        return response
    }
};

export async function deleteEmisor(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await tarjetasEmisoresDel(payload);
    return response
};

export async function updateEmisor(credentials, newEmisor, row, setInRequest) {
    if (Object.keys(newEmisor).length > 0) {
        const { nombre, codigo, bancario } = newEmisor;
        const { id, version } = row;

        const payload = {
            id,
            nombre,
            codigo,
            bancario: bancario === 'Y' ? 'B' : 'N',
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await tarjetasEmisoresUpd(payload);
        return response
    }
};