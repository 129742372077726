import axiosPost from './axiosPost';

export const usuarioMinimosGet = async (arg) => {
    const body = {
        "service": "UsuarioMinimosGet",
        "params": {}
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data.result[0]
    } catch (err) {
        console.log(err)
    }
};