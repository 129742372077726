import React, { useState, useEffect } from 'react';
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/TarjetasGrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import HeaderButton from "../../components/utils/button/HeaderButton";
// Const
import { modalsInfoPlasticos as infoModal } from "../../consts/Modals-info";
import { plasticos as titles } from '../../consts/titulos-de-tablas';
//Services 
import { retrieveData } from '../../services/Local-services';
import { tarjetasPlasticosGet } from '../../services/hooli-services/TarjetasPlasticos/TarjetasPlasticosGet';
import { tarjetasEmisoresGet } from '../../services/hooli-services/TarjetasEmisores/TarjetasEmisoresGet';
import { tarjetasMarcasGet } from '../../services/hooli-services/TarjetasMarcas/TarjetasMarcasGet';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
import addIcon from "../../assets/icons/rediseño.svg";
import reload from "../../assets/icons/actualizar.svg";
import editar from "../../assets/icons/editar.svg";
// recoil 
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { rowSelected, userCredentials, formData } from '../../recoilState/GlobalState';

export default function TarjetasPlasticos() {
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(true);
    const [plasticos, setPlasticos] = useState([]);
    const [emisores, setEmisores] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [rows, setRows] = useState([]);

    const [{ status }, setRow] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData)

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, tarjetasPlasticosGet, setPlasticos);
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) {
            updateList()
            retrieveData(credentials, undefined, tarjetasEmisoresGet, setEmisores);
            retrieveData(credentials, undefined, tarjetasMarcasGet, setMarcas);
        }
    }, [credentials]);

    useEffect(() => {
        plasticos.map((item) => {
            const {
                gwcardPlasticoId,
                gwcardPlasticoVersion,
                gwcardPlasticoNumero,
                gwcardPlasticoMarcaNombre,
                gwcardPlasticoMarcaId,
                gwcardPlasticoEmisorNombre,
                gwcardPlasticoEmisorId,
                gwcardPlasticoMarcaTipo,
                gwcardPlasticoCodigoLine
            } = item

            const plastico = {
                id: gwcardPlasticoId,
                version: gwcardPlasticoVersion,
                marcaId: gwcardPlasticoMarcaId,
                emisorId: gwcardPlasticoEmisorId,
                [titles[0]]: gwcardPlasticoNumero,
                [titles[1]]: gwcardPlasticoMarcaNombre,
                [titles[2]]: gwcardPlasticoEmisorNombre,
                [titles[3]]: gwcardPlasticoMarcaTipo === 'C' ? 'Credito' : 'Debito',
                [titles[4]]: gwcardPlasticoCodigoLine
            };

            setRows(rows => [...rows, plastico]);
        })
    }, [plasticos])

    useEffect(() => setRow({ status: false, row: {} }), []);

    
    const handleClick = ({ action, procede }) => {
        setAction({ action, procede });
        setFormInfo(infoModal[action]);
    };

    const gridContainer = document.getElementById('grid-container')
    const gridWidth = gridContainer ? gridContainer.offsetWidth : 0;

    return (
        <main>
            <TransitionModal
                titles={titles}
                procede={action.procede}
                emisores={emisores}
                marcas={marcas}
                updateList={updateList}
            />
            <Buttons>
                {status ?
                    <HeaderButton
                        text='Editar plastico de tarjeta'
                        icon={editar}
                        handleClick={handleClick}
                        data={{ action: 'edit', procede: 'Editone' }}
                    />
                    :
                    <>
                        <HeaderButton
                            text='Agregar plastico de tarjeta'
                            icon={addIcon}
                            handleClick={handleClick}
                            data={{ action: 'add', procede: 'Addone' }}
                        />
                        <HeaderButton
                            text="Actualizar pagina"
                            updateList={updateList}
                            icon={reload}
                        />
                    </>
                }
            </Buttons>
            <div className="row-grid" id="grid-container">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} indice={0} width={gridWidth} />}
            </div>
        </main>
    )
};