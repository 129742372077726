import React, { useState, useEffect } from 'react';
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/TarjetasGrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import HeaderButton from "../../components/utils/button/HeaderButton";
// Const
import { modalsInfoMarcas as infoModal } from "../../consts/Modals-info";
import { tarjetasMarcas as titles } from '../../consts/titulos-de-tablas';
//Services 
import { retrieveData } from '../../services/Local-services';
import { tarjetasMarcasGet } from '../../services/hooli-services/TarjetasMarcas/TarjetasMarcasGet';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
import addIcon from "../../assets/icons/rediseño.svg";
import reload from "../../assets/icons/actualizar.svg";
import editar from "../../assets/icons/editar.svg";
import eliminar from "../../assets/icons/eliminar.svg";
// recoil 
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formData, rowSelected, userCredentials } from '../../recoilState/GlobalState';

export default function TarjetasMarcas() {
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(true);
    const [marcas, setMarcas] = useState([]);
    const [rows, setRows] = useState([]);

    const [{ status }, setRow] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, tarjetasMarcasGet, setMarcas);
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList()
    }, [credentials]);

    useEffect(() => {
        marcas.map((item) => {
            const {
                gwcardMarcaId,
                gwcardMarcaVersion,
                gwcardMarcaNombre,
                gwcardMarcaTipo,
                gwcardMarcaCodigoLine,
                gwcardMarcaCod,
            } = item

            const marca = {
                id: gwcardMarcaId,
                version: gwcardMarcaVersion,
                tipo: gwcardMarcaTipo,
                [titles[0]]: gwcardMarcaNombre,
                [titles[1]]: gwcardMarcaTipo === 'C' ? 'Crédito' : 'Débito',
                [titles[2]]: gwcardMarcaCodigoLine,
                [titles[3]]: gwcardMarcaCod,
            };

            setRows(rows => [...rows, marca])
            return item
        })
    }, [marcas])

    useEffect(() => setRow({ status: false, row: {} }), []);

    const handleClick = ({ action, procede }) => {
        setAction({ action, procede });
        setFormInfo(infoModal[action]);
    };
    const gridContainer = document.getElementById('grid-container')
    const gridWidth = gridContainer ? gridContainer.offsetWidth : 0;

    return (
        <main>
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
            />
            <Buttons>
                {!status &&
                    <>
                        <HeaderButton
                            text="Agregar marca de tarjeta"
                            icon={addIcon}
                            handleClick={handleClick}
                            data={{ action: 'add', procede: 'Addone' }}
                        />
                        <HeaderButton
                            text={"Actualizar pagina"}
                            icon={reload}
                            updateList={updateList}
                        />
                    </>
                }
            </Buttons>
            <div className="row-grid" id="grid-container">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} indice={0} width={gridWidth} />}
            </div>
        </main>
    )
};