import axiosPost from '../axiosPost';

export const limiteSuperadoGet = async (arg) => {
    const { entidad, fecha } = arg;
    const body = {
        "service": "AfipLimiteSuperadoGet",
        "params": {
            "tipoEntidad": entidad,
            "fecha": fecha
        }
    }
    
    try {
        const result = await axiosPost('Y', body, arg)
        console.log(result.data)
        return result.data.result
    } 
    catch (err) {
        console.log(err)
    };
};