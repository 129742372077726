import React, { useState } from "react";
import { XGrid, LicenseInfo } from "@material-ui/x-grid";
import { XGridStyles } from "./Xgrid.styles";
// functions
import { generateCardColumns } from '../../../services/Local-services';
import { TextField } from "@material-ui/core";

LicenseInfo.setLicenseKey(
    "566251e0a8fd26c8758bbc5c1f9df178T1JERVI6MjE5MTUsRVhQSVJZPTE2NDYyMjIwNTcwMDAsS0VZVkVSU0lPTj0x"
);

export default function XGridDemo({ rows, titles, selected, setSelected }) {
    const [page, setPage] = useState(0);
    const [inputValue, setInputValue] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const handleSelected = (item) => {
        item.isSelected ? setSelected([...selected, item.data]) : setSelected(selected.filter(row => row.id !== item.data.id))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue === '' || parseInt(inputValue) < 1) {
            setPage(0);
            return setInputValue(1);
        };
        if (parseInt(inputValue) > numberOfPages) {
            setPage(numberOfPages);
            return setInputValue(numberOfPages + 1);
        }
        return setPage(parseInt(inputValue) - 1);
    };

    const classes = XGridStyles();
    const columns = generateCardColumns(titles);

    return (
        <>
            <XGrid
                localeText={{
                    footerTotalRows: rows.length <= 1 ? "Registro:" : "Registros:",
                    columnMenuLabel: "Menu",
                    columnMenuShowColumns: "Mostrar columnas",
                    columnMenuFilter: "Filtrar",
                    columnMenuHideColumn: "Esconder",
                    columnMenuUnsort: "Desordenar",
                    columnMenuSortAsc: "Ordenar por ASC",
                    columnMenuSortDesc: "Ordenar por DESC",
                }}
                columns={columns}
                pagination={true}
                autoPageSize
                checkboxSelection
                disableSelectionOnClick
                onRowSelected={(item) => handleSelected(item)}
                rows={rows}
                showCellRightBorder={false}
                rowHeight={36}
                hideFooterSelectedRowCount
                className={classes.root}
                page={page}
                onPageChange={(newPage) => setPage(newPage.page)}
                onStateChange={(data) => setNumberOfPages(data.state.pagination.pageCount - 1)}
            />
            <form className={classes.paginador} onSubmit={(e) => handleSubmit(e)}>
                <TextField value={inputValue} type="number" max={numberOfPages} onChange={(e) => setInputValue(e.target.value)} />
            </form>
        </>
    );
};