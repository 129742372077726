import React from 'react';
import TextField from "@material-ui/core/TextField";
// assets
import lupa from "../../assets/icons/lupa.png"
import reload from "../../assets/icons/actualizar.svg";
// components
import HeaderButton from "../../components/utils/button/HeaderButton";
import SubmitButton from '../../components/utils/button/SubmitButton';
import { FormikDateInput, FormikSelectInput } from '../../components/utils/inputs/FormikInputs';
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// styles
import { FiltrosStyles } from '../Filtros.styles';
// form
import * as yup from "yup";
import { useFormik } from "formik";
// recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../recoilState/GlobalState';

export default function Filtros(props) {
    const { filtros, setFiltros, monedas, handleClick, updateList, cargando } = props;

    const { status, row } = useRecoilValue(rowSelected);

    const validationSchema = yup.object({
        moneda: yup.string().required("Seleccione una opcion"),
        desde: yup.date().max(yup.ref('hasta'), 'No puede ser posterior a la segunda fecha'),
        hasta: yup.date().required("Por favor seleccione una fecha"),
        periodo: yup.string().required("Seleccione un opcion"),
    });

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values) 
    });

    const data = { moneda: { label: 'Moneda' }, desde: { label: 'Desde' }, hasta: { label: 'Hasta' } }
    const classes = FiltrosStyles();
    
    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <FormikSelectInput
                    data={data}
                    name="moneda"
                    formik={formik}
                    disabled={cargando}
                    options={monedas}
                    optionKey="monedaId"
                    optionValue="monedaCod"
                    optionName="monedaCod"
                    className={classes.input}
                />
                <TextField
                    label="Periodo"
                    name="periodo"
                    className={classes.input}
                    select
                    SelectProps={{ native: true }}
                    margin="normal"
                    value={formik.values.periodo}
                    onChange={formik.handleChange}
                    error={formik.touched.periodo && Boolean(formik.errors.periodo)}
                    helperText={formik.touched.periodo && formik.errors.periodo}
                    InputLabelProps={{ shrink: false }}
                >
                    <option value="M">Mes</option>
                    <option value="A">Año</option>
                    <option value="T">365</option>
                    <option value="C">Custom</option>
                </TextField>
                {formik.values.periodo === 'C' &&
                    <FormikDateInput
                        data={data}
                        name="desde"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                }
                <FormikDateInput
                    data={data}
                    name="hasta"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Filtrar" />
                </div>
            </div>
            <Buttons>
                {status ?
                    row.balanceGrupo === 'U' || row.balanceGrupo === 'M' ?
                        <HeaderButton
                            text='Ver detalle'
                            icon={lupa}
                            data='Detalle'
                            handleClick={handleClick}
                        />
                        :
                        <HeaderButton
                            text='Ver resumen'
                            icon={lupa}
                            data='Resumen'
                            handleClick={handleClick}
                        />
                    :
                    <HeaderButton
                        text='Actualizar pagina'
                        icon={reload}
                        updateList={updateList}
                    />
                }
            </Buttons>
        </form>
    )
};