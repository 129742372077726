import React, { useState, useEffect } from 'react';
// styles
import "../pages.css"
// components
import Filtros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/ConciliacionGrid";
import TransitionModal from "../../components/utils/modals/Modals";
import { modalsInfoConciliationsGIRE as infoModal } from "../../consts/Modals-info";
// Const
import { conciliacionGIRE as titles } from '../../consts/titulos-de-tablas';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
// recoil 
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formData, userCredentials } from '../../recoilState/GlobalState';
import { conciliacionesGireGet } from '../../services/hooli-services/ConciliacionGIRE/conciliacionGireGet';

export default function ConciliacionesGIRE() {
    const [conciliaciones, setConciliaciones] = useState([]);
    const [rows, setRows] = useState([]);
    const [filtros, setFiltros] = useState({
        desde: new Date().toISOString().substring(0, 10),
        hasta: new Date().toISOString().substring(0, 10),
        estado: 'todos',
        empresa: '',
        monto: null
    });
    const [procede, setProcede] = useState('');
    const [selected, setSelected] = useState([]);
    const [cargando, setCargando] = useState(true);

    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);

    async function retrieveConciliaciones() {
        const params = {
            "fechaDesde": filtros.desde,
            "fechaHasta": filtros.hasta,
            "gireConciliacionEmpresa": filtros.empresa === '' ? undefined : filtros.empresa,
            "gireConciliacionEstado": filtros.estado === 'todos' ? undefined : filtros.estado,
            "gireConciliacionMonto": filtros.monto === 0 ? null : filtros.monto,
        };
        const payload = { ...credentials, params };
        setCargando(true);
        setRows([]);

        const data = await conciliacionesGireGet(payload)
        setConciliaciones(data)
        setCargando(false)
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveConciliaciones()
    }, [credentials, filtros]);

    useEffect(() => {
        conciliaciones.map((item) => {
            const {
                gireConciliacionId,
                gireConciliacionGireEmpresa,
                gireConciliacionGireFecha,
                gireConciliacionEstado,
                gireConciliacionGireId,
                gireConciliacionGireMonto,
                gireConciliacionGireTransaccion,
                gireConciliacionHooliEmpresa,
                gireConciliacionHooliFecha,
                gireConciliacionHooliId,
                gireConciliacionHooliMonto,
                gireConciliacionHooliTransaccion
            } = item;

            const conciliacion = {
                id: gireConciliacionId || gireConciliacionHooliTransaccion || gireConciliacionGireId,
                estado: gireConciliacionEstado,
                [titles[0]]: gireConciliacionId,
                [titles[1]]: gireConciliacionHooliId,
                [titles[2]]: gireConciliacionHooliTransaccion,
                [titles[3]]: gireConciliacionHooliMonto,
                [titles[4]]: gireConciliacionHooliFecha ? gireConciliacionHooliFecha.substring(0, 10) : null,
                [titles[5]]: gireConciliacionHooliEmpresa,
                [titles[6]]: gireConciliacionGireId,
                [titles[7]]: gireConciliacionGireTransaccion,
                [titles[8]]: gireConciliacionGireMonto,
                [titles[9]]: gireConciliacionGireFecha ? gireConciliacionGireFecha.substring(0, 10) : null,
                [titles[10]]: gireConciliacionGireEmpresa,
            };
            setRows(rows => [...rows, conciliacion])
            return item
        })
    }, [conciliaciones]);

    const handleClick = ({ procede }) => {
        setFormInfo(infoModal[procede]);
        setProcede(procede);
    };

    const gridContainer = document.getElementById('grid-container')
    const gridWidth = gridContainer ? gridContainer.offsetWidth : 0;

    return (
        <main>
            <TransitionModal
                procede={procede}
                setProcede={setProcede}
                selected={selected}
                setSelected={setSelected}
                updateList={retrieveConciliaciones}
            />
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                selected={selected}
                cargando={cargando}
                handleClick={handleClick}
                updateList={retrieveConciliaciones}
            />
            <div className="row-grid-gire" id="grid-container">
                {cargando ? <CircularIndeterminate /> :
                    <XGridDemo
                        rows={rows}
                        titles={titles}
                        indice={0}
                        width={gridWidth}
                        selected={selected}
                        setSelected={setSelected}
                        setProcede={setProcede}
                    />}
            </div>
        </main>
    )
};