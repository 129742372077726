import axiosPost from '../axiosPost';

export const tarjetasPlasticosGet = async (arg) => {
    const body = {
        "service": "GWCardPlasticoGet",
        "params": {}
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data.result
    }
    catch (err) {
        console.log(err)
    }
};