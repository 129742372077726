import React, { useState } from "react";
import { XGrid, LicenseInfo } from "@material-ui/x-grid";
import { XGridStyles } from "./Xgrid.styles";
// functions
import { generateCardColumns, getGridProps, validateRowSelected } from '../../../services/Local-services';
// recoil
import { useRecoilState } from 'recoil'
import { rowSelected } from '../../../recoilState/GlobalState';
import { TextField } from "@material-ui/core";

LicenseInfo.setLicenseKey(
    "566251e0a8fd26c8758bbc5c1f9df178T1JERVI6MjE5MTUsRVhQSVJZPTE2NDYyMjIwNTcwMDAsS0VZVkVSU0lPTj0x"
);

export default function XGridDemo({ rows, titles, setSecondarySelected }) {
    const [page, setPage] = useState(0);
    const [inputValue, setInputValue] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const [{ row }, setSelected] = useRecoilState(rowSelected);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue === '' || parseInt(inputValue) < 1) {
            setPage(0);
            return setInputValue(1);
        };
        if (parseInt(inputValue) > numberOfPages) {
            setPage(numberOfPages);
            return setInputValue(numberOfPages + 1);
        }
        return setPage(parseInt(inputValue) - 1);
    };

    const setter = setSecondarySelected ? setSecondarySelected : setSelected;
    const columns = generateCardColumns(titles);
    const classes = XGridStyles();
    const gridProps = getGridProps(columns, rows, row, setter, classes, validateRowSelected);

    return (
        <>
            <XGrid
                {...gridProps}
                page={page}
                onPageChange={(newPage) => {
                    setInputValue(newPage.page + 1)
                    setPage(newPage.page)
                }}
                onStateChange={(data) => setNumberOfPages(data.state.pagination.pageCount - 1)}
            />
            <form className={classes.paginador} onSubmit={(e) => handleSubmit(e)}>
                <TextField value={inputValue} type="number" max={numberOfPages} onChange={(e) => setInputValue(e.target.value)} />
            </form>
        </>
    );
};