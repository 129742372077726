import axiosPost from '../axiosPost';

export const usuarioGet = async (arg) => {
    const { params } = arg
    const body = {
        "service": "UsuarioGet",
        "params": params ? params : {}
    }

    try {
        const result = await axiosPost('Y', body, arg)
        console.log(body, result.data)
        return result.data.result
    } catch (err) {
        console.log(err)
    }
}