import axiosPost from '../axiosPost';

export const conciliacionesCompletar = async (arg) => {
    const { operaciones } = arg

    const body = {
        "service": "BindConciliacionCompletarEntranteDo",
        "params": {
            "bindConciliacionOperacionId": operaciones[0].id
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        console.log(body, result.data);
        return result.data
    }
    catch (err) {
        console.log(err)
    };
};