// services
import { tarjetasMarcasIns } from '../../../../services/hooli-services/TarjetasMarcas/TarjetasMarcasIns';

export async function postMarca(credentials, newMarca, setInRequest) {
    if (Object.keys(newMarca).length > 0) {
        const payload = {
            ...newMarca,
            ...credentials
        };

        setInRequest(true);
        const response = await tarjetasMarcasIns(payload);
        return response
    }
};