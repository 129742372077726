// services
import { conciliacionesGireConciliar } from "../../services/hooli-services/ConciliacionGIRE/Conciliar";
import { conciliacionesGireRevisar } from "../../services/hooli-services/ConciliacionGIRE/Revisar";
import { conciliacionesGireOcultar } from "../../services/hooli-services/ConciliacionGIRE/Ocultar";
import { conciliacionesGireRevertir } from "../../services/hooli-services/ConciliacionGIRE/Revertir";
import { conciliacionesGireUpload } from "../../services/hooli-services/ConciliacionGIRE/Upload";

import { serviceURL } from "../../consts/ServiceURL";

export async function update(credentials, selected, procede, archivo, comentario) {
    const { hooliToken, sessionId } = credentials;

    const operaciones = selected ? selected.map(item => {
        return {
            origin: item["ID operación Hooli"] ? "hooli" : "gire",
            id: item["ID operación Hooli"] ? item["ID operación Hooli"] : item["ID operación Gire"]
        }
    }) : null;

    const payload = {
        archivo,
        comentario,
        operaciones,
        hooliToken,
        sessionId,
        serviceURL
    };

    switch (procede) {
        case "conciliar": return await conciliacionesGireConciliar(payload);
        case "revisar": return await conciliacionesGireRevisar(payload);
        case "ocultar": return await conciliacionesGireOcultar(payload);
        case "revertir": return await conciliacionesGireRevertir(payload);
        case "desconciliar": return await conciliacionesGireRevertir(payload);
        case "archivo": return await conciliacionesGireUpload(payload);
        default: return
    }
};