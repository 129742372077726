import React from 'react';
import { FiltrosStyles } from '../Filtros.styles';
// Components
import HeaderButton from "../../components/utils/button/HeaderButton";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import SubmitButton from '../../components/utils/button/SubmitButton';
import { FormikDateInput, FormikSelectInput } from '../../components/utils/inputs/FormikInputs';
import { TextField } from '@material-ui/core';
// icons
import reload from "../../assets/icons/actualizar.svg";
import exportarIcono from "../../assets/icons/exportar.png";
// form
import * as yup from "yup";
import { useFormik } from "formik";
import { exportarCSV } from '../../services/Local-services';

export default function Filtros({ filtros, setFiltros, cargando, updateList, transacciones, merchants }) {
    const date = new Date();
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);

    const validationSchema = yup.object({
        hasta: yup
            .date()
            .max(date.toISOString().substring(0, 10), "Fecha maxima hoy")
            .required("Por favor seleccione una fecha"),
        desde: yup
            .date()
            .max(yup.ref('hasta'), "No puede ser posterior a la segunda fecha")
            .required("Por favor seleccione una fecha"),
        merchant: yup.string().required("Por favor seleccione una opción"),
        estado: yup.string().required("Por favor seleccione una opción")
    });

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const data = { desde: { label: 'Desde' }, hasta: { label: 'Hasta' }, merchant: { label: 'Merchant' }, estado: { label: 'Estado' } };
    const classes = FiltrosStyles();

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <FormikDateInput
                    data={data}
                    name="desde"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="hasta"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikSelectInput
                    data={data}
                    name="merchant"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                    options={[{ merchantId: "todos", merchantNombre: 'Todos' }, ...merchants.filter(m => m.merchantId !== 169)]}
                    optionKey="merchantId"
                    optionValue="merchantId"
                    optionName="merchantNombre"
                />
                <TextField
                    label={data.estado.label}
                    name="estado"
                    margin="normal"
                    select
                    disabled={cargando}
                    SelectProps={{ native: true }}
                    value={formik.values.presente}
                    onChange={formik.handleChange}
                    error={formik.touched.estado && Boolean(formik.errors.estado)}
                    helperText={formik.touched.estado && formik.errors.estado}
                    InputLabelProps={{ shrink: true }}
                    className={classes.input}
                >
                    <option value="todos">Todos</option>
                    <option value="P">Pendiente</option>
                    <option value="A">Autorizado</option>
                    <option value="C">Acreditado</option>
                    <option value="V">Conciliado</option>
                    <option value="X">Anulado</option>
                    <option value="R">Cancelado</option>
                    <option value="E">En revision</option>
                    <option value="Z">Rechazado</option>
                    <option value="M">Confirmado por el comercio</option>
                    <option value="G">Rechazado por el comercio</option>
                </TextField>
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Filtrar" />
                </div>
            </div>
            <Buttons>
                <HeaderButton
                    text="Exportar archivo .csv"
                    icon={exportarIcono}
                    exportar={() => exportarCSV(transacciones, `${new Date().toISOString().substring(0, 10)}-${new Date().toTimeString().substring(0, 8)}-transacciones`)}
                />
                <HeaderButton
                    text="Actualizar pagina"
                    icon={reload}
                    updateList={updateList}
                />
            </Buttons>
        </form>
    )
};