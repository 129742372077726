import axiosPost from '../axiosPost';

export const tarjetasAcreditacionesDetalleGet = async (arg) => {
    const { fecha, merchantId } = arg
    
    const body = {
        "service": "GWCardTransaccionPorMerchantDetalleGet",
        "params": {
            "merchantId": merchantId,
            "gwcardTransaccionFechaAutorizacion": fecha,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data.result
    } catch (err) {
        console.log(err)
    }
};