import React from "react";
import { Divider, Button } from "@material-ui/core";
import styles from './DetalleMovimientoModal.module.css';
import { transformDate, formatNumber } from '../../services/Local-services';
import { useSetRecoilState } from 'recoil'
import { formIsOpen } from '../../recoilState/GlobalState';
import CircularIndeterminate from "../../components/utils/spinner/Spinner";

export default function DetalleMovimientoModal(props) {
    const setOpen = useSetRecoilState(formIsOpen);

    const { details, cargando, movimiento } = props.props;

    return (
        cargando ? <CircularIndeterminate /> :
            <>
                <h2 className={styles.title}>Detalle del movimiento</h2>
                <Divider className={styles.divider} />
                <div className={styles.detalle}>
                    <div className={styles.nombre}>Movimiento</div>
                    <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTTipo === 'S' ? 'Cash Out' : 'Cash In' : details[0].bindConciliacionBTTipo === 'S' ? 'Cash Out' : 'Cash In' : null}</div>
                </div>
                <Divider className={styles.divider} />

                <div className={styles.detalle}>
                    <div className={styles.nombre}>ID conciliacion</div>
                    <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTConciliacionId : details[0].bindConciliacionBTConciliacionId : null}</div>
                </div>
                <Divider className={styles.divider} />


                {details && details[0] && movimiento === 'H' ?
                    <>
                        <div className={styles.detalle}>
                            <div className={styles.nombre}>ID origen</div>
                            <div className={styles.info}>{details[0].bindConciliacionHTOrigen}</div>
                        </div>
                <Divider className={styles.divider} />

                    </> : null}
                <div className={styles.detalle}>
                    <div className={styles.nombre}>ID Hooli / Bind</div>
                    <div className={styles.info}>{details && details[0] ?
                        movimiento === 'H' ? details[0].bindConciliacionHTId :
                            details[0].bindConciliacionBTId : null}</div>
                </div>
                <Divider className={styles.divider} />

                <div className={styles.detalle}>
                    <div className={styles.nombre}>Monto</div>
                    <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? formatNumber(details[0].bindConciliacionHTMonto) : formatNumber(details[0].bindConciliacionBTMonto) : null}</div>
                </div>
                <Divider className={styles.divider} />

                <div className={styles.detalle}>
                    <div className={styles.nombre}>Fecha</div>
                    <div className={styles.info}>{
                        details && details[0] ?
                            movimiento === 'H' ?
                                details[0].bindConciliacionHTFecha ? `${transformDate(details[0].bindConciliacionHTFecha.substring(0, 10))} ${details[0].bindConciliacionHTFecha.substring(10)}` :
                                    details[0].bindConciliacionBTFecha : `${transformDate(details[0].bindConciliacionBTFecha.substring(0, 10))} ${details[0].bindConciliacionBTFecha.substring(10)}` :
                            null}
                    </div>
                </div>
                <Divider className={styles.divider} />

                <div className={styles.detalle}>
                    <div className={styles.nombre}>CUIT Origen</div>
                    <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTCUITOrigen : details[0].bindConciliacionBTCUITOrigen : null}</div>
                </div>
                <Divider className={styles.divider} />

                <div className={styles.detalle}>
                    <div className={styles.nombre}>CUIT Destino</div>
                    <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTCUITDestino : details[0].bindConciliacionBTCUITDestino : null}</div>
                </div>
                <Divider className={styles.divider} />

                <div className={styles.detalle}>
                    <div className={styles.nombre}>Nombre origen</div>
                    <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTNombreOrigen : details[0].bindConciliacionBTNombreOrigen : null}</div>
                </div>
                <Divider className={styles.divider} />

                <div className={styles.detalle}>
                    <div className={styles.nombre}>Nombre destino</div>
                    <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTNombreDestino : details[0].bindConciliacionBTNombreDestino : null}</div>
                </div>
                <Divider className={styles.divider} />

                {details && details[0] && movimiento === 'H' && details[0].bindConciliacionHTTipo === 'E' ?
                    <>
                        <div className={styles.detalle}>
                            <div className={styles.nombre}>Banco origen</div>
                            <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTBancoOrigen : null : null}</div>
                        </div>
                <Divider className={styles.divider} />

                    </> : null}
                {details && details[0] && movimiento === 'H' && details[0].bindConciliacionHTTipo === 'S' ?
                    <>
                        <div className={styles.detalle}>
                            <div className={styles.nombre}>Banco destino</div>
                            <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTBancoDestino : null : null}</div>
                        </div>
                <Divider className={styles.divider} />

                    </> : null}
                <div className={styles.detalle}>
                    <div className={styles.nombre}>CVU origen</div>
                    <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTCVUOrigen : details[0].bindConciliacionBTCVUOrigen : null}</div>
                </div>
                <Divider className={styles.divider} />

                <div className={styles.detalle}>
                    <div className={styles.nombre}>CVU destino</div>
                    <div className={styles.info}>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTCVUDestino : details[0].bindConciliacionBTCVUDestino : null}</div>
                </div>
                <Divider className={styles.divider} />

                <div style={{ width: '100%' }}>
                    <div className={styles.nombre}>Observación</div>
                    <p>{details && details[0] ? movimiento === 'H' ? details[0].bindConciliacionHTConciliacionObservacion : details[0].bindConciliacionBTConciliacionObservacion : null}</p>
                </div>
                <Divider className={styles.divider} />

                <Button
                    style={{
                        color: "#ffffff",
                        width: "16rem",
                        height: "2rem",
                        background: "linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)",
                        borderRadius: 21,
                        opacity: 1,
                        textTransform: "none",
                        fontWeight: 300,
                        fontFamily: '"Roboto", sans-serif',
                        marginTop: '1em',
                    }}
                    onClick={() => setOpen(false)}
                >Cerrar</Button>
            </>
    );
};