import React, { useState } from "react";
// Utils
import FormButton from "../../components/utils/button/FormButton";
import CommonForm from "../../components/utils/form/CommonForm";
// Services
import { formatNumber, handleResponse } from "../../services/Local-services";
import { conciliacionesAcreditar } from "../../services/hooli-services/Conciliaciones/Acreditar";
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, snackbarData, formData } from '../../recoilState/GlobalState'

export default function ConfirmarAcreditacionBindModal(props) {
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { selected, setSelected, updateList } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        const payload = { ...credentials, ids: selected.map(item => item.bindId) };

        setInRequest(true);
        const response = await conciliacionesAcreditar(payload);

        return handleResponse(response, 'Operacion exitosa', functions, true)
    };

    return (
        <CommonForm
            onSubmit={() => { }}
            title={data.title}
        >
            <p>Esta seguro que desea acreditar {selected.length > 1 ? 'las operaciones' : 'la operación'} con ID:</p>
            {selected.map(item => <p key={item.bindID} style={{ margin: '0.5em 0' }}>{item.bindId}</p>)}
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};