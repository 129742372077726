import axiosPost from '../axiosPost';

export const conciliacionesUpload = async (arg) => {
    const { archivo, tipoDeArchivo } = arg

    const body = {
        "service": "BindConciliacionUpload",
        "params": {
            "bindConciliacionArchivoContenido": archivo,
            "bindConciliacionArchivoTipo": tipoDeArchivo
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
};