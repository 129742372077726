import React, { useState, useEffect } from 'react';
// styles
import "../pages.css"
// utils
import XGridDemo from "../../components/utils/grid/TarjetasGrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import HeaderButton from "../../components/utils/button/HeaderButton";
// Const
import { modalsInfoCoeficientes as infoModal } from "../../consts/Modals-info";
import { coeficientes as titles } from '../../consts/titulos-de-tablas';
//Services 
import { tarjetasCoeficientesGet } from '../../services/hooli-services/TarjetasCoeficientes/TarjetasCoeficientesGet';
import { tarjetasCoeficientesUpd } from '../../services/hooli-services/TarjetasCoeficientes/TarjetasCoeficientesUpd';
import { handleResponse } from '../../services/Local-services';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
import addIcon from "../../assets/icons/rediseño.svg";
import reload from "../../assets/icons/actualizar.svg";
import activarConInteres from "../../assets/icons/con-activar.svg";
import desactivarConInteres from "../../assets/icons/con-desactivar.svg";
import activarSinInteres from "../../assets/icons/sin-activar.svg";
import desactivarSinInteres from "../../assets/icons/sin-desactivar.svg";
// recoil 
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { formData, formIsOpen, rowSelected, snackbarData, userCredentials } from '../../recoilState/GlobalState';

export default function TarjetasCoeficientes({ route }) {
    const [action, setAction] = useState({});
    const [coeficientes, setCoeficientes] = useState([]);
    const [rows, setRows] = useState([]);
    const [cargando, setCargando] = useState(true);

    const [{ row, status }, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setIsOpen = useSetRecoilState(formIsOpen);

    const id = window.location.pathname.split('/')[2];

    async function retrieveCoeficientes() {
        const payload = { ...credentials, id };
        setRows([]);
        setCargando(true);

        const data = await tarjetasCoeficientesGet(payload);

        setCoeficientes(data);
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveCoeficientes()
    }, [credentials]);

    useEffect(() => {
        if (coeficientes.length > 0) {
            function getCoefs() {
                const data = [];

                for (let i = 2; i < 51; i++) {
                    const coefsThisCuota = coeficientes.filter(c => c.gwcardCoeficienteCantidadCuotas === i);

                    let idC = null, vigenciaC = null, valorC = null, activoC = null, versionC = null;
                    let idS = null, vigenciaS = null, valorS = null, activoS = null, versionS = null;
                    let idH = null, vigenciaH = null, valorH = null, versionH = null;
                    // Hay con interes
                    const coefc = coefsThisCuota.find(c => c.gwcardCoeficienteTipo === 'C');
                    if (coefc) {
                        idC = coefc.gwcardCoeficienteId;
                        vigenciaC = coefc.gwcardCoeficienteDesde;
                        valorC = coefc.gwcardCoeficienteValor;
                        activoC = coefc.gwcardCoeficienteActivo;
                        versionC = coefc.gwcardCoeficienteVersion;
                    }
                    // Sin interes
                    const coefs = coefsThisCuota.find(c => c.gwcardCoeficienteTipo === 'S');
                    if (coefs) {
                        idS = coefs.gwcardCoeficienteId;
                        vigenciaS = coefs.gwcardCoeficienteDesde;
                        valorS = coefs.gwcardCoeficienteValor;
                        activoS = coefs.gwcardCoeficienteActivo;
                        versionS = coefs.gwcardCoeficienteVersion
                    }
                    // Hooli
                    const coefh = coefsThisCuota.find(c => c.gwcardCoeficienteTipo === 'H');
                    if (coefh) {
                        idH = coefh.gwcardCoeficienteId;
                        vigenciaH = coefh.gwcardCoeficienteDesde;
                        valorH = coefh.gwcardCoeficienteValor;
                        versionH = coefh.gwcardCoeficienteVersion
                    }

                    data.push({
                        idC,
                        vigenciaC,
                        valorC,
                        activoC,
                        versionC,
                        idS,
                        vigenciaS,
                        valorS,
                        activoS,
                        versionS,
                        idH,
                        vigenciaH,
                        valorH,
                        versionH,
                        cantidad: i,
                    })
                }
                return data
            }
            const newCoefs = getCoefs();
            newCoefs.map((item) => {
                const {
                    idC, idS, idH,
                    versionC, versionS, versionH,
                    cantidad,
                    vigenciaC, vigenciaS, vigenciaH,
                    activoC, activoS,
                    valorC, valorS, valorH
                } = item;

                const coeficiente = {
                    id: `${idC}${idS}${idH}${cantidad}`,
                    idC,
                    idS,
                    idH,
                    versionC,
                    versionS,
                    versionH,
                    activoC,
                    activoS,
                    [titles[0]]: cantidad,
                    [titles[1]]: vigenciaC,
                    [titles[2]]: activoC === 'Y' ? 'Si' : 'No',
                    [titles[3]]: valorC,
                    [titles[4]]: vigenciaS,
                    [titles[5]]: activoS === 'Y' ? 'Si' : 'No',
                    [titles[6]]: valorS,
                    [titles[7]]: vigenciaH,
                    [titles[8]]: valorH,
                };

                setRows(rows => [...rows, coeficiente])
                return item
            })
        }
    }, [coeficientes]);

    useEffect(() => setSelected({ status: false }), []);

    const handleClick = ({ action, procede }) => {
        setAction({ action, procede });
        setFormInfo(infoModal[action]);
    };

    useEffect(() => {
        const functions = {
            setSnackbarInfo,
            setInRequest: setCargando,
            setIsOpen,
            setSelected,
            updateList: retrieveCoeficientes
        };

        (async () => {
            if (action.action === 'noOpen') {
                const params = {
                    gwcardCoeficienteId: row ? action.procede === 'S' ? row.idS : row.idC : null,
                    gwcardCoeficienteVersion: row ? action.procede === 'S' ? row.versionS : row.versionC : null,
                    gwcardCoeficienteMarcaId: id
                };
                const payload = { ...credentials, params }
                const response = await tarjetasCoeficientesUpd(payload);

                handleResponse(response, 'Coeficiente modificado exitosamente', functions)
            }
        })();
    }, [action])

    const gridContainer = document.getElementById('grid-container')
    const gridWidth = gridContainer ? gridContainer.offsetWidth : 0;

    return (
        <main>
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={retrieveCoeficientes}
                id={id}
            />
            <Buttons>
                {status ?
                    <>
                        {row && row.idC &&
                            <HeaderButton
                                text={row && row.activoC === 'Y' ? 'Desactivar con interes' : 'Activar con interes'}
                                icon={row && row.activoC === 'Y' ? desactivarConInteres : activarConInteres}
                                handleClick={handleClick}
                                data={{ action: 'noOpen', procede: 'C' }}
                            />}
                        {row && row.idS &&
                            <HeaderButton
                                text={row && row.activoS === 'Y' ? 'Desactivar sin interes' : 'Activar sin interes'}
                                icon={row && row.activoS === 'Y' ? desactivarSinInteres : activarSinInteres}
                                handleClick={handleClick}
                                data={{ action: 'noOpen', procede: 'S' }}
                            />}
                    </>
                    :
                    <>
                        <HeaderButton
                            text='Actualizar pagina'
                            icon={reload}
                            updateList={retrieveCoeficientes}
                        />
                        <HeaderButton
                            text="Añadir coeficiente"
                            icon={addIcon}
                            handleClick={handleClick}
                            data={{ action: 'add', procede: 'Addone' }}
                        />
                    </>
                }
            </Buttons>
            <div className="row-grid" id="grid-container">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} indice={0} width={gridWidth} />}
            </div>
        </main>
    )
};