import axiosPost from '../axiosPost';

export const feriadosUpd = async (arg) => {
    const { id, nombre, version } = arg;

    const body = {
        "service": "FeriadoUpd",
        "params": {
            'feriadoId': id,
            "feriadoNombre": nombre,
            "feriadoVersion": version
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
}