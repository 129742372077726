import React, { useState, useEffect } from 'react';
// components
import HeaderButton from "../../components/utils/button/HeaderButton";
import SubmitButton from '../../components/utils/button/SubmitButton';
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import { FormikDateInput, FormikNumberInput, FormikSelectInput, FormikTextInput } from '../../components/utils/inputs/FormikInputs';
// functions
import { formatNumber } from '../../services/Local-services';
// styles
import { FiltrosStyles } from '../Filtros.styles';
// icons
import borrar from "../../assets/icons/borrar.svg";
import reload from "../../assets/icons/actualizar.svg";
import conciliar from "../../assets/icons/conciliar.png";
import desconciliar from "../../assets/icons/desconciliar.svg";
import omitir from "../../assets/icons/omitir.svg";
import quitarDeOmitir from "../../assets/icons/quitarDeOmitir.svg";
import revisar from "../../assets/icons/revisar.svg";
import quitarDeRevision from "../../assets/icons/quitarDeRevision.svg";
import importar from "../../assets/icons/importar.png";
// form
import * as yup from "yup";
import { useFormik } from "formik";

export default function Filtros(props) {
    const { selected, cargando, filtros, setFiltros, handleClick, updateList } = props;
    const [total, setTotal] = useState(0)

    useEffect(() => {
        setTotal(selected.reduce((acc, val) => acc + val["Monto Hooli"] - val["Monto Gire"], 0));
    }, [selected])

    const validationSchema = yup.object({
        desde: yup
            .date()
            .max(yup.ref('hasta'), 'No puede posterior a la segunda fecha')
            .required("Por favor seleccione una fecha"),
        hasta: yup
            .date()
            .max(new Date().toISOString().substring(0, 10), "No puede posterior a hoy")
            .required("Por favor seleccione una fecha"),
        estado: yup.string().required("Seleccione un campo"),
        monto: yup.number().min(0, "No puede ser menor a cero"),
        empresa: yup.string()
    });

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const data = { estado: { label: 'Estado' }, empresa: { label: 'Empresa' }, desde: { label: 'Desde' }, hasta: { label: 'Hasta' }, monto: { label: 'Monto' } };
    const estados = [{ value: 'todos', name: 'Todos' }, { value: 'C', name: 'Conciliados' }, { value: 'P', name: 'No conciliados' }, { value: 'R', name: 'En revisión' }, { value: 'O', name: 'Ocultos' },];
    const classes = FiltrosStyles();

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <FormikSelectInput
                    data={data}
                    name="estado"
                    formik={formik}
                    disabled={cargando}
                    options={estados}
                    optionKey='value'
                    optionValue='value'
                    optionName='name'
                    className={classes.input}
                />
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="empresa"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.empresa !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('empresa', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <FormikDateInput
                    data={data}
                    name="desde"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="hasta"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikNumberInput
                    data={data}
                    name="monto"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Filtrar" />
                </div>
                <p className={`${classes.total} ${classes.marginNormal}`}>Total: {formatNumber(total)}</p>
            </div>
            <Buttons>
                {selected.length === 0 &&
                    <>
                        <HeaderButton
                            text={'Actualizar pagina'}
                            icon={reload}
                            updateList={updateList}
                        />
                        <HeaderButton
                            text="Subir archivo .csv"
                            icon={importar}
                            handleClick={handleClick}
                            data={{ action: 'importar', procede: 'archivo' }}
                        />
                    </>
                }
                {selected.length === 1 && selected[0].estado === 'P' &&
                    <>
                        <HeaderButton
                            text={'Ocultar'}
                            icon={omitir}
                            handleClick={handleClick}
                            data={{ procede: 'ocultar' }}
                        />
                        <HeaderButton
                            text={'Revisar'}
                            icon={revisar}
                            handleClick={handleClick}
                            data={{ procede: 'revisar' }}
                        />
                    </>
                }
                {selected.length === 1 && selected[0].estado === 'O' &&
                    <HeaderButton
                        text={'Dejar de omitir'}
                        icon={quitarDeOmitir}
                        handleClick={handleClick}
                        data={{ procede: 'revertir' }}
                    />
                }
                {selected.length === 1 && selected[0].estado === 'R' &&
                    <HeaderButton
                        text={'Quitar de revision'}
                        icon={quitarDeRevision}
                        handleClick={handleClick}
                        data={{ procede: 'revertir' }}
                    />
                }
                {selected.length === 1 &&
                    selected[0].estado === 'C' &&
                    selected[0]["ID conciliación"][0] === 'M' &&
                    selected[0]["ID operación Hooli"] &&
                    selected[0]["ID operación Gire"] &&
                    <HeaderButton
                        text={'Desconciliar'}
                        icon={desconciliar}
                        handleClick={handleClick}
                        data={{ procede: 'desconciliar' }}
                    />
                }
                {selected.length > 1 && selected.every(row => row.estado === 'P') && total === 0 &&
                    <HeaderButton
                        text={'Conciliar'}
                        icon={conciliar}
                        handleClick={handleClick}
                        data={{ procede: 'conciliar' }}
                    />
                }
                {selected.length > 1 && selected.every(row => row.estado === "C" && row["ID de conciliacion"] === selected[0]["ID de conciliacion"]) && total === 0 &&
                    <HeaderButton
                        text={'Desconciliar'}
                        icon={desconciliar}
                        handleClick={handleClick}
                        data={{ procede: 'desconciliar' }}
                    />
                }
            </Buttons>
        </form>
    )
};