import React, { useState, useRef } from "react";
import { TextField } from "@material-ui/core";
// styles
import "../../components/utils/form/form.css";
// utils
import FormButton from "../../components/utils/button/FormButton";
import CommonForm from "../../components/utils/form/CommonForm";
// functions
import { update } from "./ConciliacionesFunctions";
import { handleResponse } from "../../services/Local-services";
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, snackbarData, formData } from '../../recoilState/GlobalState'

export default function ComentarioModal(props) {
    const [comentario, setComentario] = useState('');
    const [inRequest, setInRequest] = useState(false);
    const [archivo, setArchivo] = useState('');
    const [nombreArchivo, setNombreArchivo] = useState('');

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { selected, setSelected, procede, updateList } = props.props;

    async function handleAction(e) {
        e.preventDefault();
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        setInRequest(true)
        const response = await update(credentials, selected, procede, archivo, comentario);
        return handleResponse(response, 'Operacion exitosa', functions, true)
    };

    const inputFile = useRef(null);
    const onButtonClick = () => inputFile.current.click();

    const handleReaderLoaded = (readerEvt) => {
        const fileContent = readerEvt.currentTarget.result.split('\n').filter(i => i !== '\r' && i !== '').join('-!?-');
        setArchivo(fileContent)
    };

    const handleFile = (e) => {
        let file = e.target.files[0];
        if (file) {
            setNombreArchivo(file.name)
            const reader = new FileReader();
            reader.onload = handleReaderLoaded;
            reader.readAsText(file)
        }
    };

    return (
        <CommonForm
            onSubmit={() => { }}
            title={procede === 'archivo' ? 'Importar archivo' : 'Añadir observacion'}
            procede={procede}
        >
            {procede === 'archivo' ?
                <>
                    <input
                        style={{ display: "none" }}
                        accept=".csv"
                        ref={inputFile}
                        type="file"
                        onChange={handleFile}
                    />
                    <button type="button" className="add-file-button" onClick={() => onButtonClick()}>
                        Busca en el explorador
                    </button>
                    <p>{nombreArchivo}</p>
                </>
                :
                <TextField
                    label="Observacion"
                    name="comentario"
                    placeholder="Añadir observacion"
                    fullWidth
                    disabled={procede === 'ocultar' || procede === 'revertir' || procede === 'revisar' ? inRequest : true}
                    multiline
                    type="text"
                    value={comentario}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setComentario(e.target.value)}
                />
            }
            <FormButton
                data={data}
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm >
    );
};