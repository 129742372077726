import axiosPost from '../axiosPost';

export const tarjetasPlasticosIns = async (arg) => {
    const { numero, marca, marcaTipo, emisor, codigo } = arg
    
    const body = {
        "service": "GWCardPlasticoIns",
        "params": {
            "gwcardPlasticoNumero": numero,
            "gwcardPlasticoCodigoLine": codigo,
            "gwcardMarcaTipo": marcaTipo,
            "gwcardMarcaCod": marca,
            "gwcardEmisorCod": emisor,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
}