import React from 'react';
import TextField from "@material-ui/core/TextField";
import { FiltrosStyles } from '../Filtros.styles';
// assets
import addIcon from "../../assets/icons/rediseño.svg";
import reload from "../../assets/icons/actualizar.svg";
import editar from "../../assets/icons/editar.svg";
import eliminar from "../../assets/icons/eliminar.svg";
// Components
import HeaderButton from "../../components/utils/button/HeaderButton";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
// recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../recoilState/GlobalState';

export default function Filtros(props) {
    const { status } = useRecoilValue(rowSelected);

    const { feriados, feriadoSeleccionado, setFeriadoSeleccionado, handleClick, cargando, updateList } = props;

    const classes = FiltrosStyles();

    return (
        <div className={classes.root}>
            <div className={classes.filtros}>
                <TextField
                    label="Feriado"
                    name="feriado"
                    value={feriadoSeleccionado}
                    onChange={e => setFeriadoSeleccionado(e.target.value)}
                    disabled={cargando}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: false }}
                    className={classes.input}
                >
                    {feriados.map(f => {
                        return <option key={f.feriadoId} value={f.feriadoId}>{f.feriadoNombre}</option>
                    })}
                </TextField>
            </div>
            <Buttons>
                {status ?
                    <>
                        <HeaderButton
                            text='Editar serie'
                            icon={editar}
                            handleClick={handleClick}
                            data={{ action: 'edit', procede: 'Editone' }}
                        />
                        <HeaderButton
                            text='Eliminar serie'
                            icon={eliminar}
                            handleClick={handleClick}
                            data={{ action: 'delete', procede: 'Deleteone' }}
                        />
                    </>
                    :
                    <>
                        <HeaderButton
                            text='Agregar serie de feriados'
                            icon={addIcon}
                            handleClick={handleClick}
                            data={{ action: 'add', procede: 'Addone' }}
                        />
                        <HeaderButton
                            text={"Actualizar pagina"}
                            icon={reload}
                            updateList={updateList}
                        />
                    </>
                }
            </Buttons>
        </div>
    )
};