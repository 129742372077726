import axiosPost from '../axiosPost';

export const limitesUpd = async (arg) => {
    const { id, diaMonto, diaCantidad, mesMonto, mesCantidad, version, entidad } = arg;

    const tabla = entidad === 'U' ? "usuario" : "merchant";
    const body = {
        "service": entidad === 'U' ? "UsuarioLimiteUpd" : "MerchantLimiteUpd",
        "params": {
            [`${tabla}Id`]: id,
            [`${tabla}LimiteDiaMonto`]: diaMonto,
            [`${tabla}LimiteDiaCantidad`]: diaCantidad,
            [`${tabla}LimiteMesMonto`]: mesMonto,
            [`${tabla}LimiteMesCantidad`]: mesCantidad,
            [`${tabla}Version`]: version
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
}