import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    cuotas: yup.number("Debe ser un numero").min(2, "Minimo 2").max(51, "Maximo 51").required("Ingrese cuotas"),
    tipo: yup.string().required("Seleccione una opción"),
    vigencia: yup.date().min(new Date().toISOString().substring(0, 10), "Solo fechas presentes o futuras").required("Seleccione una fecha"),
    valor: yup.string().required("Ingrese un valor"),
});

export const formikValidation = (setNewCoeficiente) => formik({
    initialValues: {
        cuotas: '',
        tipo: 'C',
        vigencia: new Date().toISOString().substring(0, 10),
        valor: ''
    },
    validationSchema,
    onSubmit: (values) => setNewCoeficiente(values)
});