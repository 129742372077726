import React, { useState, useEffect } from 'react';
// styles
import "../pages.css"
// utils
import XGridDemo from "../../components/utils/grid/AcreditacionesGrid";
import Filtros from './Filtros';
import TransitionModal from '../../components/utils/modals/Modals';
// Const
import { acreditaciones as titles } from '../../consts/titulos-de-tablas';
import { modalsInfoAcreditaciones as infoModal } from "../../consts/Modals-info";
//Services 
import { tarjetasAcreditacionesGet } from '../../services/hooli-services/TarjetasAcreditaciones/TarjetasAcreditacionesGet';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
// recoil 
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formData, userCredentials } from '../../recoilState/GlobalState';

export default function TarjetasAcreditaciones() {
    const [action, setAction] = useState({});
    const [acreditaciones, setAcreditaciones] = useState([]);
    const [filtros, setFiltros] = useState({
        fecha: new Date().toISOString().substring(0, 10)
    });
    const [rows, setRows] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [selected, setSelected] = useState([]);

    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);

    async function retrieveAcreditaciones() {
        const payload = { ...credentials, fecha: filtros.fecha };
        setRows([]);
        setSelected([]);
        setCargando(true);

        const data = await tarjetasAcreditacionesGet(payload)
        setAcreditaciones(data)
        setCargando(false)
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveAcreditaciones()
    }, [credentials, filtros]);

    useEffect(() => {
        acreditaciones.map((item, index) => {
            const {
                gwardTransaccionMontoTotalMerchant,
                gwcardTransaccionMerchantNombre,
                gwcardTransaccionTotalOperaciones,
                gwcardTansaccionAcreditacionMarca
            } = item;

            const acreditacion = {
                id: index,
                auto: gwcardTansaccionAcreditacionMarca,
                gwcardTransaccionMerchantNombre,
                gwardTransaccionMontoTotalMerchant,
                gwcardTransaccionTotalOperaciones,
                [titles[0]]: gwcardTransaccionMerchantNombre,
                [titles[1]]: gwardTransaccionMontoTotalMerchant,
                [titles[2]]: gwcardTransaccionTotalOperaciones,
            };

            setRows(rows => [...rows, acreditacion])
            return item
        });
    }, [acreditaciones]);

    const handleClick = ({ action, procede }) => {
        setAction({ action, procede });
        setFormInfo(infoModal[action]);
    };

    return (
        <main>
            <TransitionModal
                selected={selected}
                setSelected={setSelected}
                updateList={retrieveAcreditaciones}
                filtros={filtros}
                procede={action.procede}
            />
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                selected={selected}
                handleClick={handleClick}
                updateList={retrieveAcreditaciones}
                cargando={cargando}
            />
            <div className="row-grid" id="grid-container">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} indice={0} selected={selected} setSelected={setSelected} />}
            </div>
        </main>
    )
};