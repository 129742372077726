import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    numero: yup.number('Deben ser solo numeros')
        .test('len', 'Debe tener 8 numeros', (val) => {
            if(val) return val.toString().length === 8
        })
        .required("Ingrese un numero"),
    marca: yup.string().required("Seleccione una opción"),
    emisor: yup.string().required("Seleccione una opción"),
    codigo: yup.string().required("Ingrese un codigo"),
});

export const formikValidation = (setNewPlastico, row, titles, marcas, emisores) => formik({
    initialValues: {
        numero: row && row[titles[0]] ? row[titles[0]] : '',
        marca: row && row.marcaId ? row.marcaId : marcas[0].gwcardMarcaId,
        emisor: row && row.emisorId ? row.emisorId : emisores[0].gwcardEmisorId,
        codigo: row && row[titles[4]] ? row[titles[4]] : '',
    },
    validationSchema,
    onSubmit: (values) => setNewPlastico(values)
});