import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    fecha: yup.date().min(new Date().toISOString().substring(0, 10), 'Debe ser una fecha presente o futura').required('Seleccione una fecha'),
    condicionAFIP: yup.number().required("Seleccione una opción"),
    diaMonto: yup.number().required("Ingrese un monto"),
    diaCantidad: yup.number().required("Ingrese una cantidad"),
    mesMonto: yup.number().required("Ingrese un monto"),
    mesCantidad: yup.number().required("Ingrese una cantidad")
})

export const formikValidation = (setNewLimite, row, titles, condiciones) => formik({
    initialValues: {
        fecha: row && row[titles[1]] ? row[titles[1]] : new Date().toISOString().substring(0, 10),
        condicionAFIP: row && row.condicionAFIPId ? row.condicionAFIPId : condiciones[0]?.condicionAFIPId,
        diaMonto: row && row[titles[2]] ? row[titles[2]] : 1000,
        diaCantidad: row && row[titles[3]] ? row[titles[3]] : 1000,
        mesMonto: row && row[titles[4]] ? row[titles[4]] : 1000,
        mesCantidad: row && row[titles[5]] ? row[titles[5]] : 1000
    },
    validationSchema,
    onSubmit: (values) => setNewLimite(values)
});