import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from "../../components/utils/buttonsContainer/Buttons";
// consts
import { limites as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoLimites as infoModal } from "../../consts/Modals-info";
import addIcon from "../../assets/icons/rediseño.svg";
import reload from "../../assets/icons/actualizar.svg";
import editar from "../../assets/icons/editar.svg";
import eliminar from "../../assets/icons/eliminar.svg";
import HeaderButton from "../../components/utils/button/HeaderButton";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { limitesGet } from '../../services/hooli-services/Limites/LimitesGet';
import { condicionesAFIPGet } from "../../services/hooli-services/Limites/CondicionesAFIPGet";
// recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { rowSelected, userCredentials, formData } from '../../recoilState/GlobalState';

export default function Limites() {
    const [limites, setLimites] = useState([]);
    const [condiciones, setCondiciones] = useState([]);
    const [rows, setRows] = useState([]);
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(false);

    const [row, setRow] = useRecoilState(rowSelected)
    const setFormInfo = useSetRecoilState(formData)
    const credentials = useRecoilValue(userCredentials);

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, limitesGet, setLimites)
        await retrieveData(credentials, undefined, condicionesAFIPGet, setCondiciones)
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList()
    }, [credentials]);

    useEffect(() => {
        limites.map((item) => {
            const {
                condicionAFIPLimiteId,
                condicionAFIPId,
                condicionAFIPNombre,
                condicionAFIPLimiteVersion,
                condicionAFIPLimiteDiaMonto,
                condicionAFIPLimiteDiaCantidad,
                condicionAFIPLimiteMesMonto,
                condicionAFIPLimiteMesCantidad,
                condicionAFIPLimiteFecha
            } = item

            const limite = {
                id: condicionAFIPLimiteId,
                condicionAFIPId,
                version: condicionAFIPLimiteVersion,
                [titles[0]]: condicionAFIPNombre,
                [titles[1]]: condicionAFIPLimiteFecha,
                [titles[2]]: condicionAFIPLimiteDiaMonto,
                [titles[3]]: condicionAFIPLimiteDiaCantidad,
                [titles[4]]: condicionAFIPLimiteMesMonto,
                [titles[5]]: condicionAFIPLimiteMesCantidad
            }

            setRows(rows => [...rows, limite])
            return item
        })
    }, [limites]);

    useEffect(() => setRow({ status: false, row: {} }), []);


    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action])
        setAction({ action, procede });
    };

    return (
        <main>
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
                condiciones={condiciones}
                limites={limites}
            />
            <Buttons>
                {!row.status &&
                    <>
                        <HeaderButton
                            text={"Agregar límite"}
                            icon={addIcon}
                            handleClick={handleClick}
                            data={{ action: 'add', procede: 'Addone' }}
                        />
                        <HeaderButton
                            text={"Actualizar pagina"}
                            icon={reload}
                            updateList={updateList}
                        />
                    </>
                }
            </Buttons>
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};