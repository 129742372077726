import axiosPost from '../axiosPost';

export const tarjetasCoeficientesGet = async (arg) => {
    const { id } = arg

    const body = {
        "service": "GWCardCoeficienteGet",
        "params": {
            "gwcardCoeficienteMarcaId": id
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data.result
    } catch (err) {
        console.log(err)
    }
};