import React from 'react';
import HeaderButton from "../../components/utils/button/HeaderButton";
import Buttons from "../../components/utils/buttonsContainer/Buttons";
import SubmitButton from '../../components/utils/button/SubmitButton';
import { FiltrosStyles } from '../Filtros.styles';
// icons
import reload from "../../assets/icons/actualizar.svg";
import pesos from "../../assets/icons/pesos.png";
import lupa from "../../assets/icons/lupa.png";
// form
import * as yup from "yup";
import { useFormik } from "formik";
import { FormikDateInput } from '../../components/utils/inputs/FormikInputs';

export default function Filtros(props) {
    const { filtros, setFiltros, selected, handleClick, updateList, cargando } = props;

    const validationSchema = yup.object({
        fecha: yup.date()
            .max(new Date().toISOString().substring(0, 10), "Fecha maxima hoy")
            .required("Por favor seleccione una fecha")
    });

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const classes = FiltrosStyles();
    const data = { fecha: { label: "Fecha acreditación" } }

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <FormikDateInput
                    data={data}
                    name="fecha"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Filtrar" />
                </div>
            </div>
            <Buttons>
                {selected.length === 0 && <HeaderButton
                    text="Actualizar pagina"
                    icon={reload}
                    updateList={updateList}
                />
                }
                {selected.length === 1 && selected[0].auto !== 'AA' && <HeaderButton
                    text="Ver detalle de operaciones"
                    icon={lupa}
                    handleClick={handleClick}
                    data={{ action: "detalle", procede: "detalle" }}
                />
                }
                {selected.length > 0 && <HeaderButton
                    text="Acreditar"
                    icon={pesos}
                    handleClick={handleClick}
                    data={{ action: "confirmar", procede: "confirmar" }}
                />
                }
            </Buttons>
        </form >
    )
};