import React, { useState } from "react";
import eliminar from '../../../../assets/icons/eliminar.svg'
import editar from '../../../../assets/icons/editar.svg'
// utils
import FormButton from "../../button/FormButton";
import { FormikNumberInput } from '../../inputs/FormikInputs';
import CommonForm from "../CommonForm";
// functions
import { updateLimite } from './ControlLimitesFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './ControlLimitesFormValidaciones';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState'

export default function ControlLimitesForm(props) {
    const [newLimite, setNewLimite] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };

        if (procede === 'Editone') {
            const response = await updateLimite(credentials, newLimite, selected.row, setInRequest)
            return handleResponse(response, 'Límite modificado exitosamente', functions)
        }
    };

    const formik = formikValidation(setNewLimite, selected.row);

    const handleClick = (field, value) => () => {
        formik.setFieldValue(field, value)
    }

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <div className="form__input-row">
                {formik.values.diaMonto !== null ?
                    <>
                        <FormikNumberInput
                            data={data}
                            disabled={inRequest}
                            name="diaMonto"
                            formik={formik}
                        />
                        <img
                            src={eliminar}
                            onClick={handleClick('diaMonto', null)}
                            title="Usar valor por default"
                        />
                    </>
                    :
                    <>
                        <FormikNumberInput
                            data={data}
                            disabled
                            name="diaMonto"
                            formik={formik}
                        />
                        <img
                            src={editar}
                            onClick={handleClick('diaMonto', selected.row?.diaLimiteMonto)}
                            title="Usar valor personalizado"
                        />
                    </>
                }
            </div>
            <div className="form__input-row">
                {formik.values.diaCantidad !== null ?
                    <>
                        <FormikNumberInput
                            data={data}
                            disabled={inRequest}
                            name="diaCantidad"
                            formik={formik}
                        />
                        <img
                            src={eliminar}
                            onClick={() => formik.setFieldValue('diaCantidad', null)}
                            title="Usar valor por default"
                        />
                    </>
                    :
                    <>
                        <FormikNumberInput
                            data={data}
                            disabled
                            name="diaCantidad"
                            formik={formik}
                        />
                        <img
                            src={editar}
                            onClick={handleClick('diaCantidad', selected.row?.diaLimiteCantidad)}
                            title="Usar valor personalizado"
                        />
                    </>
                }
            </div>
            <div className="form__input-row">
                {formik.values.mesMonto !== null ?
                    <>
                        <FormikNumberInput
                            data={data}
                            disabled={inRequest}
                            name="mesMonto"
                            formik={formik}
                        />
                        <img
                            src={eliminar}
                            onClick={() => formik.setFieldValue('mesMonto', null)}
                            title="Usar valor por default"
                        />
                    </>
                    :
                    <>
                        <FormikNumberInput
                            data={data}
                            disabled
                            name="mesMonto"
                            formik={formik}
                        />
                        <img
                            src={editar}
                            onClick={handleClick('mesMonto', selected.row?.mesLimiteMonto)}
                            title="Usar valor personalizado"
                        />
                    </>
                }
            </div>
            <div className="form__input-row">
                {formik.values.mesCantidad !== null ?
                    <>

                        <FormikNumberInput
                            data={data}
                            disabled={inRequest}
                            name="mesCantidad"
                            formik={formik}
                        />
                        <img
                            src={eliminar}
                            onClick={() => formik.setFieldValue('mesCantidad', null)}
                            title="Usar valor por default"
                        />
                    </>
                    :
                    <>
                        <FormikNumberInput
                            data={data}
                            disabled
                            name="mesCantidad"
                            formik={formik}
                        />
                        <img
                            src={editar}
                            onClick={handleClick('mesCantidad', selected.row?.mesLimiteCantidad)}
                            title="Usar valor personalizado"
                        />
                    </>
                }
            </div>
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};