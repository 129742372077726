import React, { useState, useEffect } from 'react'
// assets
import lupa from "../../assets/icons/lupa.png";
import reload from "../../assets/icons/actualizar.svg";
// components
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
import HeaderButton from '../../components/utils/button/HeaderButton'
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import XGridDemo from '../../components/utils/grid/Xgrid';
import TransitionModal from "../../components/utils/modals/Modals";
import SubmitButton from '../../components/utils/button/SubmitButton';
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import { FormikDateInput, FormikSelectInput } from '../../components/utils/inputs/FormikInputs';
// form
import * as yup from "yup";
import { useFormik } from "formik";
// const
import { modalBalance as titles } from '../../consts/titulos-de-tablas';
// services
import { balanceGet } from '../../services/hooli-services/Balance/BalanceGet';
// recoil 
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, rowSelected, formIsOpen } from '../../recoilState/GlobalState';
// styles
import { FiltrosStyles } from '../Filtros.styles';
import styles from './modalesBalance.module.css';

export default function DetalleModal({ props }) {
    const { filtros: initialFiltros, monedas } = props;

    const [filtros, setFiltros] = useState(initialFiltros);
    const [balance, setBalance] = useState([]);
    const [balanceRows, setBalanceRows] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [open, setOpen] = useState(false);
    const [secondarySelected, setSecondarySelected] = useState({ status: false, row: {} });

    const initialSelected = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setFormOpen = useSetRecoilState(formIsOpen);

    async function retrieveBalance() {
        const params = {
            "fechaHasta": filtros.hasta,
            "monedaCod": filtros.moneda,
            "periodo": filtros.periodo,
            "fechaDesde": filtros.desde,
            "balanceTipo": initialSelected.row.balanceGrupo
        }
        const payload = { ...credentials, params };
        setCargando(true);
        setBalanceRows([]);

        const data = await balanceGet(payload);

        setCargando(false)
        setBalance(data)
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveBalance()
    }, [credentials, filtros])

    useEffect(() => {
        balance.map((item) => {
            const {
                balanceNombre,
                balanceAnterior,
                balanceDebitos,
                balanceCreditos,
                balanceActual,
                balanceGrupo,
                balanceEntidadId
            } = item

            const balance = {
                id: balanceEntidadId,
                balanceGrupo,
                balanceEntidadId,
                [titles[0]]: balanceNombre,
                [titles[1]]: balanceAnterior,
                [titles[2]]: balanceDebitos,
                [titles[3]]: balanceCreditos,
                [titles[4]]: balanceActual,
            }

            setBalanceRows(rows => [...rows, balance])
            return item
        });
    }, [balance]);

    const handleClick = () => setOpen(true);

    const validationSchema = yup.object({
        moneda: yup.string().required("Seleccione una opcion"),
        desde: yup.date().max(yup.ref('hasta'), 'No puede ser posterior a la segunda fecha'),
        hasta: yup.date().required("Por favor seleccione una fecha"),
        periodo: yup.string().required("Seleccione un opcion"),
    });

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const data = { moneda: { label: 'Moneda' }, desde: { label: 'Desde' }, hasta: { label: 'Hasta' } }
    const classes = FiltrosStyles();

    return (
        <div className={styles.container}>
            {open &&
                <TransitionModal
                    filtros={filtros}
                    monedas={monedas}
                    setOpen={setOpen}
                    procede='Resumen'
                    row={secondarySelected.row}
                />
            }
            <h2 className={styles.title}>Detalle - {initialSelected.row.Cuenta}</h2>
            <form className={classes.root} onSubmit={formik.handleSubmit}>
                <div className={classes.filtros}>
                    <FormikSelectInput
                        data={data}
                        name="moneda"
                        formik={formik}
                        disabled={cargando}
                        options={monedas}
                        optionKey="monedaId"
                        optionValue="monedaCod"
                        optionName="monedaCod"
                        className={classes.input}
                    />
                    <TextField
                        label="Periodo"
                        name="periodo"
                        className={classes.input}
                        select
                        SelectProps={{ native: true }}
                        margin="normal"
                        value={formik.values.periodo}
                        onChange={formik.handleChange}
                        error={formik.touched.periodo && Boolean(formik.errors.periodo)}
                        helperText={formik.touched.periodo && formik.errors.periodo}
                        InputLabelProps={{ shrink: false }}
                    >
                        <option value="M">Mes</option>
                        <option value="A">Año</option>
                        <option value="T">365</option>
                        <option value="C">Custom</option>
                    </TextField>
                    {formik.values.periodo === 'C' &&
                        <FormikDateInput
                            data={data}
                            name="desde"
                            formik={formik}
                            disabled={cargando}
                            className={classes.input}
                        />
                    }
                    <FormikDateInput
                        data={data}
                        name="hasta"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    <div className={classes.marginNormal}>
                        <SubmitButton texto="Filtrar" />
                    </div>
                </div>
                <Buttons>
                    {secondarySelected.status ?
                        <HeaderButton
                            text='Ver resumen'
                            icon={lupa}
                            handleClick={handleClick}
                            data={{ action: 'resumen' }}
                        />
                        :
                        <HeaderButton
                            text='Actualizar pagina'
                            icon={reload}
                            updateList={retrieveBalance}
                        />
                    }
                </Buttons>
            </form>
            <div id="second-grid-container">
                {cargando ? <CircularIndeterminate /> :
                    <XGridDemo
                        rows={balanceRows}
                        titles={titles}
                        secondarySelected={secondarySelected}
                        setSecondarySelected={setSecondarySelected}
                    />
                }
            </div>
            <div className={styles.buttonContainer}>
                <Button className={styles.buttonCerrar} onClick={() => setFormOpen(false)}>
                    Cerrar
                </Button>
            </div>
        </div>
    )
};