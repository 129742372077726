import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import Filtros from "./Filtros";
import TransitionModal from "../../components/utils/modals/Modals";
// consts
import { controlLimites as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoControlLimites as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { limiteSuperadoGet } from '../../services/hooli-services/Limites/LimiteSuperadoGet';
import { formatNumber } from "../../services/Local-services";
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { rowSelected, userCredentials, formData } from '../../recoilState/GlobalState';

export default function ControlLimites() {
    const [limites, setLimites] = useState([]);
    const [rows, setRows] = useState([]);
    const [action, setAction] = useState({});
    const [filtros, setFiltros] = useState({ entidad: 'U', fecha: new Date().toISOString().substring(0, 10) })
    const [cargando, setCargando] = useState(false);

    const setRow = useSetRecoilState(rowSelected)
    const setFormInfo = useSetRecoilState(formData)
    const credentials = useRecoilValue(userCredentials);

    const updateList = async () => {
        setCargando(true);
        setRows([]);
        const payload = { ...credentials, ...filtros }
        const data = await limiteSuperadoGet(payload);
        setLimites(data);
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList()
    }, [credentials, filtros]);

    useEffect(() => {
        limites.map((item, index) => {
            const {
                usuarioId,
                merchantId,
                usuarioVersion,
                merchantVersion,
                diaCantidad,
                diaLimiteCantidad,
                diaLimiteMonto,
                diaMonto,
                mesCantidad,
                mesLimiteCantidad,
                mesLimiteMonto,
                mesMonto,
                usuarioNombre,
                usuarioCuit,
                merchantNombre,
                merchantCuit,
                condicionAfipNombre,
                diaExcedePropio,
                mesExcedePropio
            } = item

            const limite = {
                id: index,
                userId: usuarioId || merchantId,
                version: usuarioVersion !== null && usuarioVersion !== undefined ? usuarioVersion : merchantVersion,
                entidad: filtros.entidad,
                diaLimiteMonto,
                diaLimiteCantidad,
                mesLimiteMonto,
                mesLimiteCantidad,
                diaMonto,
                diaCantidad,
                mesMonto,
                mesCantidad,
                diaExcedePropio,
                mesExcedePropio,
                [titles[0]]: usuarioNombre || merchantNombre,
                [titles[1]]: usuarioCuit || merchantCuit,
                [titles[2]]: condicionAfipNombre,
                [titles[3]]: formatNumber(diaMonto),
                [titles[4]]: formatNumber(diaCantidad),
                [titles[5]]: formatNumber(mesMonto),
                [titles[6]]: formatNumber(mesCantidad)
            }

            setRows(rows => [...rows, limite])
            return item
        })
    }, [limites]);

    useEffect(() => setRow({ status: false, row: {} }), []);


    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action])
        setAction({ action, procede });
    };

    return (
        <main>
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
            />
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                cargando={cargando}
                updateList={updateList}
                handleClick={handleClick}
            />
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};