// services
import { tarjetasPlasticosIns } from '../../../../services/hooli-services/TarjetasPlasticos/TarjetasPlasticosIns';
import { tarjetasPlasticosUpd } from '../../../../services/hooli-services/TarjetasPlasticos/TarjetasPlasticosUpd';

export async function postPlastico(credentials, newPlastico, marcas, emisores, setInRequest) {
    if (Object.keys(newPlastico).length > 0) {
        const { numero, marca, emisor, codigo } = newPlastico;

        const marcaSeleccionada = marcas.find(m => m.gwcardMarcaId === parseInt(marca));
        const emisorSeleccionado = emisores.find(m => m.gwcardEmisorId === parseInt(emisor));

        const payload = {
            numero,
            marca: marcaSeleccionada.gwcardMarcaCod,
            marcaTipo: marcaSeleccionada.gwcardMarcaTipo,
            emisor: emisorSeleccionado.gwcardEmisorCod,
            codigo,
            ...credentials
        };

        setInRequest(true);
        const response = await tarjetasPlasticosIns(payload);
        return response
    }
};

export async function updatePlastico(credentials, newPlastico, row, emisores, setInRequest) {
    if (Object.keys(newPlastico).length > 0) {
        const { id, version } = row;
        const { emisor, codigo } = newPlastico;

        const emisorSeleccionado = emisores.find(m => m.gwcardEmisorId === parseInt(emisor));
        const payload = {
            id,
            codigo,
            emisor: emisorSeleccionado.gwcardEmisorCod,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await tarjetasPlasticosUpd(payload);
        return response
    }
};