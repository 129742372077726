import axiosPost from '../axiosPost';

export const tarjetasAcreditacionesGet = async (arg) => {
    const { fecha } = arg

    const body = {
        "service": "GWCardTransaccionPorMerchantGet",
        "params": {
            "gwcardTransaccionFechaAutorizacion": fecha,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data.result
    } catch (err) {
        console.log(err)
    }
};