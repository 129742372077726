import axiosPost from '../axiosPost';

export const conciliacionBTGet = async (arg) => {
    const { id } = arg

    const body = {
        "service": "BindConciliacionBTGet",
        "params": {
            "bindTransferenciaId": id
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data.result
    } 
    catch (err) {
        console.log(err)
    };
};