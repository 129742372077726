import axiosPost from '../axiosPost';

export const conciliacionesGireRevertir = async (arg) => {
    const { operaciones, comentario } = arg

    const body = {
        "service": "GireConciliacionRevertir",
        "params": {
            "gireConciliacionOperaciones": operaciones,
            "gireConciliacionObservacion": comentario
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } 
    catch (err) {
        console.log(err)
    };
};