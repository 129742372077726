// services
import { limitesIns } from '../../../../services/hooli-services/Limites/LimitesIns';

export async function postLimite(credentials, newLimite, setInRequest) {
    if (Object.keys(newLimite).length > 0) {
        const payload = { ...credentials, ...newLimite };

        setInRequest(true);
        const response = await limitesIns(payload);
        return response
    }
};